/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */
export const wheelDiameter = [
  { key: '15 or smaller', label: '15 or smaller' },
  { key: '16', label: '16' },
  { key: '17', label: '17' },
  { key: '18', label: '18' },
  { key: '19', label: '19' },
  { key: '20 or greater', label: '20 or greater' }
];

export const wheelBolt = [
  { key: 'Other', label: 'Other' },
  { key: '4x100', label: '4x100' },
  { key: '4x108 ', label: '4x108 ' },
  { key: '4x114.3', label: '4x114.3' },
  { key: '5x98', label: '5x98' },
  { key: '5x100', label: '5x100' },
  { key: '5x108', label: '5x108' },
  { key: '5x110', label: '5x110' },
  { key: '5x112', label: '5x112' },
  { key: '5x114.3', label: '5x114.3' },
  { key: '5x115', label: '5x115' },
  { key: '5x120', label: '5x120' },
  { key: '5x130', label: '5x130' },
  { key: '6x114.3', label: '6x114.3' },
];

export const subOptionsWheels = [
  {
    key: "Other",
    label: "Other"
  },
  {
    key: "OEM",
    label: "OEM"
  },
  {
    key: "3SDM",
    label: "3SDM"
  },
  {
    key: "AC-Schnitzer",
    label: "AC-Schnitzer"
  },
  {
    key: "ACT",
    label: "ACT"
  },
  {
    key: "Acura",
    label: "Acura"
  },
  {
    key: "ADV-1",
    label: "ADV-1"
  },
  {
    key: "Advan",
    label: "Advan"
  },
  {
    key: "Alpina",
    label: "Alpina"
  },
  {
    key: "American-Racing",
    label: "American-Racing"
  },
  {
    key: "Apex",
    label: "Apex"
  },
  {
    key: "ASA",
    label: "ASA"
  },
  {
    key: "Avant-Garde",
    label: "Avant-Garde"
  },
  {
    key: "Azev",
    label: "Azev"
  },
  {
    key: "BBS",
    label: "BBS"
  },
  {
    key: "BC-Forged",
    label: "BC-Forged"
  },
  {
    key: "Borbet",
    label: "Borbet"
  },
  {
    key: "Brabus",
    label: "Brabus"
  },
  {
    key: "Breyton",
    label: "Breyton"
  },
  {
    key: "Brock",
    label: "Brock"
  },
  {
    key: "Carline",
    label: "Carline"
  },
  {
    key: "CCW",
    label: "CCW"
  },
  {
    key: "Compomotive",
    label: "Compomotive"
  },
  {
    key: "Cosmis-Racing",
    label: "Cosmis-Racing"
  },
  {
    key: "Curva",
    label: "Curva"
  },
  {
    key: "Desmond",
    label: "Desmond"
  },
  {
    key: "Enkei",
    label: "Enkei"
  },
  {
    key: "Equip",
    label: "Equip"
  },
  {
    key: "ESM",
    label: "ESM"
  },
  {
    key: "ESR",
    label: "ESR"
  },
  {
    key: "Fifteen52",
    label: "Fifteen52"
  },
  {
    key: "Forgestar",
    label: "Forgestar"
  },
  {
    key: "Hartge",
    label: "Hartge"
  },
  {
    key: "Hartmann",
    label: "Hartmann"
  },
  {
    key: "Hayashi-Racing",
    label: "Hayashi-Racing"
  },
  {
    key: "HRE",
    label: "HRE"
  },
  {
    key: "Keskin",
    label: "Keskin"
  },
  {
    key: "Klutch",
    label: "Klutch"
  },
  {
    key: "Konig",
    label: "Konig"
  },
  {
    key: "Lexani",
    label: "Lexani"
  },
  {
    key: "Lorenzo",
    label: "Lorenzo"
  },
  {
    key: "Lorinser",
    label: "Lorinser"
  },
  {
    key: "MIRO",
    label: "MIRO"
  },
  {
    key: "Moda",
    label: "Moda"
  },
  {
    key: "MOMO",
    label: "MOMO"
  },
  {
    key: "Motegi-Racing",
    label: "Motegi-Racing"
  },
  {
    key: "Motorsport",
    label: "Motorsport"
  },
  {
    key: "MSW",
    label: "MSW"
  },
  {
    key: "Niche",
    label: "Niche"
  },
  {
    key: "O.Z. Racing",
    label: "O.Z. Racing"
  },
  {
    key: "Rays",
    label: "Rays"
  },
  {
    key: "Revolve",
    label: "Revolve"
  },
  {
    key: "RH",
    label: "RH"
  },
  {
    key: "Rial",
    label: "Rial"
  },
  {
    key: "Ronal",
    label: "Ronal"
  },
  {
    key: "Rota",
    label: "Rota"
  },
  {
    key: "Rotiform",
    label: "Rotiform"
  },
  {
    key: "Schmidt",
    label: "Schmidt"
  },
  {
    key: "Sparco",
    label: "Sparco"
  },
  {
    key: "Speedline",
    label: "Speedline"
  },
  {
    key: "SSR",
    label: "SSR"
  },
  {
    key: "Stahl",
    label: "Stahl"
  },
  {
    key: "Stance",
    label: "Stance"
  },
  {
    key: "Starform",
    label: "Starform"
  },
  {
    key: "Steelies",
    label: "Steelies"
  },
  {
    key: "Tracer-Tech",
    label: "Tracer-Tech"
  },
  {
    key: "TSW",
    label: "TSW"
  },
  {
    key: "Varrstoen",
    label: "Varrstoen"
  },
  {
    key: "VIP-Modular",
    label: "VIP-Modular"
  },
  {
    key: "VMR",
    label: "VMR"
  },
  {
    key: "Volk",
    label: "Volk"
  },
  {
    key: "Vorsteiner",
    label: "Vorsteiner"
  },
  {
    key: "Vossen",
    label: "Vossen"
  },
  {
    key: "Wald",
    label: "Wald"
  },
  {
    key: "WCI",
    label: "WCI"
  },
  {
    key: "Weds",
    label: "Weds"
  },
  {
    key: "WORK",
    label: "WORK"
  },
  {
    key: "XXR",
    label: "XXR"
  }
];

export const wheelWidths = [
  { key: '6', label: '6' },
  { key: '6.5', label: '6.5' },
  { key: '7', label: '7' },
  { key: '7.5', label: '7.5' },
  { key: '8', label: '8' },
  { key: '8.5', label: '8.5' },
  { key: '9', label: '9' },
  { key: '9.5', label: '9.5' },
  { key: '10', label: '10' },
  { key: '10.5', label: '10.5' },
  { key: '11', label: '11' },
  { key: '11.5', label: '11.5' },
  { key: '12', label: '12' },
  { key: '12.5', label: '12.5' },
  { key: '13', label: '13' },
  { key: '13.5', label: '13.5' },
  { key: '14', label: '14' },
  { key: '14.5', label: '14.5' },
  { key: '15', label: '15' },
];

export const subOptionsSteering = [
  { key: 'Billet', label: 'Billet' },
  { key: 'Cosworth', label: 'Cosworth' },
  { key: 'Forgiato', label: 'Forgiato' },
  { key: 'GT', label: 'GT' },
  { key: 'Grant', label: 'Grant' },
  { key: 'Grip Royal', label: 'Grip Royal' },
  { key: 'JS', label: 'JS' },
  { key: 'Joes Racing', label: 'Joes Racing' },
  { key: 'Keys', label: 'Keys' },
  { key: 'Lecarra', label: 'Lecarra' },
  { key: 'Luisi', label: 'Luisi' },
  { key: 'MOMO', label: 'MOMO' },
  { key: 'Mooneyes', label: 'Mooneyes' },
  { key: 'Moto Lite', label: 'Moto Lite' },
  { key: 'Mugen', label: 'Mugen' },
  { key: 'NRG', label: 'NRG' },
  { key: 'Nardi', label: 'Nardi' },
  { key: 'OMP', label: 'OMP' },
  { key: 'other', label: 'Other' },
  { key: 'Sabelt', label: 'Sabelt' },
  { key: 'Scott Drake', label: 'Scott Drake' },
  { key: 'Skoda', label: 'Skoda' },
  { key: 'Sparco', label: 'Sparco' },
  { key: 'Springalex', label: 'Springalex' },
  { key: 'Toms', label: "Tom's" },
  { key: 'Vertex', label: 'Vertex' },
  { key: 'Volante', label: 'Volante' },
];

export const partTypeOptions = [
  { key: 'Car Specific', label: 'Car Specific' },
  { key: 'Universal', label: 'Universal' },
];

export const subOptionsSeats = [
  { key: 'Other', label: 'Other' },
  { key: 'OEM', label: 'OEM' },
  { key: 'Bride', label: 'Bride' },
  { key: 'Recaro', label: 'Recaro' },
  { key: 'MOMO', label: 'MOMO' },
  { key: 'Seibon', label: 'Seibon' },
  { key: 'NRG', label: 'NRG' },
  { key: 'Cipher', label: 'Cipher' },
  { key: 'Corbeau', label: 'Corbeau' },
  { key: 'Sparco', label: 'Sparco' },
  { key: 'JEGS', label: 'JEGS' },
  { key: 'Mugen', label: 'Mugen' },
  { key: 'Buddy Club', label: 'Buddy Club' },
  { key: 'Takata', label: 'Takata' },
  { key: 'Anderson Composites', label: 'Anderson Composites' },
  { key: 'Braum', label: 'Braum' },
  { key: 'ButlerBuilt', label: 'Butler Built' },
  { key: 'Cipher Auto', label: 'Cipher Auto' },
  { key: 'Cobra Seats', label: 'Cobra Seats' },
  { key: 'Black Mountain', label: 'Black Mountain' },
  { key: 'Cusco', label: 'Cusco' },
  { key: 'Impact', label: 'Impact' },
  { key: 'JAZ', label: 'JAZ' },
  { key: 'KIRKEY', label: 'KIRKEY' },
  { key: 'OMP', label: 'OMP' },
  { key: 'Procar', label: 'Procar' },
  { key: 'Pyrotect', label: 'Pyrotect' },
  { key: 'Racetech', label: 'Racetech' },
  { key: 'RCI', label: 'RCI' },
  { key: 'Sabelt', label: 'Sabelt' },
  { key: 'Spec-D', label: 'Spec-D' },
  { key: 'Spyder Xtune', label: 'Spyder Xtune' },
  { key: 'Ultra Shield', label: 'Ultra Shield' },
];

const offSetArr = [];
for (var x = -100; x < 100; x++) {
  offSetArr.push({ key: x, label: x });
}

JSON.stringify({ array: offSetArr });

export const offSet = offSetArr;

const yearsArr = [];
for (var x = 1960; x < 2023; x++) {
  yearsArr.push({ key: JSON.stringify(x), label: JSON.stringify(x) });
}

JSON.stringify({ array: yearsArr });

export const years = yearsArr && yearsArr.reverse();

export const filters = [
  {
    id: 'category',
    label: 'Category',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // key is the option you see in Flex Console.
      // label is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'interior-parts', label: 'Interior Parts' },
        { key: 'body', label: 'Body' },
        { key: 'light-assemblies', label: 'Light Assemblies' },
        { key: 'braking', label: 'Braking' },
        { key: 'suspension', label: 'Suspension' },
        { key: 'wheels', label: 'Wheels' },
        { key: 'electronics', label: 'Electronics' },
        { key: 'engine', label: 'Engine' },
        { key: 'transmission', label: 'Transmission & Drivetrain' },
        { key: 'heating-cooling', label: 'Heating/Cooling' },
        { key: 'exhaust', label: 'Exhaust' },
        { key: 'chassis', label: 'Chassis' },
        { key: 'air-fuel', label: 'Air & Fuel' },
        { key: 'electrical', label: 'Electrical & Wiring' },
        { key: 'engine-bay', label: 'Engine Bay Accessories' },
        { key: 'general-accessories', label: 'General Accessories' },
        { key: 'forced-induction', label: 'Forced Induction' },
        { key: 'steering', label: 'Steering' },
        { key: 'seats', label: 'Seats' },
        { key: 'sound', label: 'Sound' },
        { key: 'other', label: 'Other' },
      ],
    },
  },
  {
    id: 'steering-wheel-brand',
    label: 'Steering Wheel Brand',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_subcategory'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple labels when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // key is the option you see in Flex Console.
      // label is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: subOptionsSteering,
    },
  },
  {
    id: 'part-type',
    label: 'Part Type',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_partType'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple labels when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // key is the option you see in Flex Console.
      // label is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: partTypeOptions,
    },
  },
  {
    id: 'wheel-brand',
    label: 'Wheel Brand',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_subcategory'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple labels when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // key is the option you see in Flex Console.
      // label is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: subOptionsWheels,
    },
  },
  {
    id: 'wheel-diameter',
    label: 'Wheel Diameter',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_wheelDiameter'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple labels when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // key is the option you see in Flex Console.
      // label is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: wheelDiameter,
    },
  },
  {
    id: 'width',
    label: 'Width',
    type: 'PriceFilter',
    group: 'primary',
    queryParamNames: ['pub_width'],
    config: {
      min: 6,
      max: 15,
      step: 0.5,
    },
  },
  {
    id: 'wheel-bolt',
    label: 'Bolt Pattern',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_wheelBolt'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple labels when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // key is the option you see in Flex Console.
      // label is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: wheelBolt,
    },
  },
  {
    id: 'offSet',
    label: 'OffSet',
    type: 'PriceFilter',
    group: 'primary',
    queryParamNames: ['pub_offSet'],
    config: {
      min: -100,
      max: 100,
      step: 1,
    },
  },
  {
    id: 'seat-brand',
    label: 'Seat Brand',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_subcategory'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple labels when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // key is the option you see in Flex Console.
      // label is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: subOptionsSeats,
    },
  },
  {
    id: 'year',
    label: 'Year',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_year'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple labels when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // key is the option you see in Flex Console.
      // label is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: years,
    },
  },
  {
    id: 'make',
    label: 'Make',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_make'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple labels when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // key is the option you see in Flex Console.
      // label is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: "AC",
          label: "AC"
        },
        {
          key: "Acura",
          label: "Acura"
        },
        {
          key: "Alfa Romeo",
          label: "Alfa Romeo"
        },
        {
          key: "Allard",
          label: "Allard"
        },
        {
          key: "Am General",
          label: "Am General"
        },
        {
          key: "AMC",
          label: "AMC"
        },
        {
          key: "American Bantam",
          label: "American Bantam"
        },
        {
          key: "American Motors",
          label: "American Motors"
        },
        {
          key: "Ariel",
          label: "Ariel"
        },
        {
          key: "Ascari",
          label: "Ascari"
        },
        {
          key: "Aston Martin",
          label: "Aston Martin"
        },
        {
          key: "Asuna",
          label: "Asuna"
        },
        {
          key: "Auburn",
          label: "Auburn"
        },
        {
          key: "Audi",
          label: "Audi"
        },
        {
          key: "Austin",
          label: "Austin"
        },
        {
          key: "Austin-Healey",
          label: "Austin-Healey"
        },
        {
          key: "Autobianchi",
          label: "Autobianchi"
        },
        {
          key: "Autozam",
          label: "Autozam"
        },
        {
          key: "Avanti",
          label: "Avanti"
        },
        {
          key: "Avanti Motors",
          label: "Avanti Motors"
        },
        {
          key: "Beaumont",
          label: "Beaumont"
        },
        {
          key: "Bedford",
          label: "Bedford"
        },
        {
          key: "Bentley",
          label: "Bentley"
        },
        {
          key: "Bertone",
          label: "Bertone"
        },
        {
          key: "BMC",
          label: "BMC"
        },
        {
          key: "BMW",
          label: "BMW"
        },
        {
          key: "Bricklin",
          label: "Bricklin"
        },
        {
          key: "Bristol",
          label: "Bristol"
        },
        {
          key: "Bugatti",
          label: "Bugatti"
        },
        {
          key: "Buick",
          label: "Buick"
        },
        {
          key: "Cadillac",
          label: "Cadillac"
        },
        {
          key: "Caterham",
          label: "Caterham"
        },
        {
          key: "Checker",
          label: "Checker"
        },
        {
          key: "Chevrolet",
          label: "Chevrolet"
        },
        {
          key: "Chrysler",
          label: "Chrysler"
        },
        {
          key: "Citroen",
          label: "Citroen"
        },
        {
          key: "Cizeta",
          label: "Cizeta"
        },
        {
          key: "Clenet",
          label: "Clenet"
        },
        {
          key: "Commuter Cars",
          label: "Commuter Cars"
        },
        {
          key: "Cord",
          label: "Cord"
        },
        {
          key: "Crosley",
          label: "Crosley"
        },
        {
          key: "Daewoo",
          label: "Daewoo"
        },
        {
          key: "DAF",
          label: "DAF"
        },
        {
          key: "Daihatsu",
          label: "Daihatsu"
        },
        {
          key: "Daimler",
          label: "Daimler"
        },
        {
          key: "Datsun",
          label: "Datsun"
        },
        {
          key: "Delahaye",
          label: "Delahaye"
        },
        {
          key: "Delorean",
          label: "Delorean"
        },
        {
          key: "Desoto",
          label: "Desoto"
        },
        {
          key: "DeTomaso",
          label: "DeTomaso"
        },
        {
          key: "Diamond T",
          label: "Diamond T"
        },
        {
          key: "Dodge",
          label: "Dodge"
        },
        {
          key: "Dual-Ghia",
          label: "Dual-Ghia"
        },
        {
          key: "Duesenberg",
          label: "Duesenberg"
        },
        {
          key: "Eagle",
          label: "Eagle"
        },
        {
          key: "Edsel",
          label: "Edsel"
        },
        {
          key: "Essex",
          label: "Essex"
        },
        {
          key: "Eunos",
          label: "Eunos"
        },
        {
          key: "Excalibur",
          label: "Excalibur"
        },
        {
          key: "Facel Vega",
          label: "Facel Vega"
        },
        {
          key: "Fargo",
          label: "Fargo"
        },
        {
          key: "Ferrari",
          label: "Ferrari"
        },
        {
          key: "FIAT",
          label: "FIAT"
        },
        {
          key: "Fisker",
          label: "Fisker"
        },
        {
          key: "Ford",
          label: "Ford"
        },
        {
          key: "Franklin",
          label: "Franklin"
        },
        {
          key: "Freightliner",
          label: "Freightliner"
        },
        {
          key: "Gardner",
          label: "Gardner"
        },
        {
          key: "Genesis",
          label: "Genesis"
        },
        {
          key: "Geo",
          label: "Geo"
        },
        {
          key: "GGMC",
          label: "GGMC"
        },
        {
          key: "Ginetta",
          label: "Ginetta"
        },
        {
          key: "GMC",
          label: "GMC"
        },
        {
          key: "Graham",
          label: "Graham"
        },
        {
          key: "Hillman",
          label: "Hillman"
        },
        {
          key: "Holden",
          label: "Holden"
        },
        {
          key: "Honda",
          label: "Honda"
        },
        {
          key: "HSV",
          label: "HSV"
        },
        {
          key: "Hudson",
          label: "Hudson"
        },
        {
          key: "Humber",
          label: "Humber"
        },
        {
          key: "Hummer",
          label: "Hummer"
        },
        {
          key: "Hupmobile",
          label: "Hupmobile"
        },
        {
          key: "Hyundai",
          label: "Hyundai"
        },
        {
          key: "INFINITI",
          label: "INFINITI"
        },
        {
          key: "Intermeccanica",
          label: "Intermeccanica"
        },
        {
          key: "International",
          label: "International"
        },
        {
          key: "International Harvester",
          label: "International Harvester"
        },
        {
          key: "Iso",
          label: "Iso"
        },
        {
          key: "Isuzu",
          label: "Isuzu"
        },
        {
          key: "Italdesign",
          label: "Italdesign"
        },
        {
          key: "Jaguar",
          label: "Jaguar"
        },
        {
          key: "Jeep",
          label: "Jeep"
        },
        {
          key: "Jensen",
          label: "Jensen"
        },
        {
          key: "Kaiser",
          label: "Kaiser"
        },
        {
          key: "Karma",
          label: "Karma"
        },
        {
          key: "Kia",
          label: "Kia"
        },
        {
          key: "Koenigsegg",
          label: "Koenigsegg"
        },
        {
          key: "Lada",
          label: "Lada"
        },
        {
          key: "LaDarwi",
          label: "LaDarwi"
        },
        {
          key: "Lagonda",
          label: "Lagonda"
        },
        {
          key: "Lamborghini",
          label: "Lamborghini"
        },
        {
          key: "Lanchester",
          label: "Lanchester"
        },
        {
          key: "Lancia",
          label: "Lancia"
        },
        {
          key: "Land Rover",
          label: "Land Rover"
        },
        {
          key: "LaSalle",
          label: "LaSalle"
        },
        {
          key: "Lexus",
          label: "Lexus"
        },
        {
          key: "Leyland",
          label: "Leyland"
        },
        {
          key: "Lincoln",
          label: "Lincoln"
        },
        {
          key: "Locomobile",
          label: "Locomobile"
        },
        {
          key: "London",
          label: "London"
        },
        {
          key: "Lotus",
          label: "Lotus"
        },
        {
          key: "Lucid",
          label: "Lucid"
        },
        {
          key: "Maserati",
          label: "Maserati"
        },
        {
          key: "Maxwell",
          label: "Maxwell"
        },
        {
          key: "Maybach",
          label: "Maybach"
        },
        {
          key: "Mazda",
          label: "Mazda"
        },
        {
          key: "McLaren",
          label: "McLaren"
        },
        {
          key: "Mercedes-Benz",
          label: "Mercedes-Benz"
        },
        {
          key: "Mercury",
          label: "Mercury"
        },
        {
          key: "Merkur",
          label: "Merkur"
        },
        {
          key: "Messerschmitt",
          label: "Messerschmitt"
        },
        {
          key: "MEV",
          label: "MEV"
        },
        {
          key: "MG",
          label: "MG"
        },
        {
          key: "MINI",
          label: "MINI"
        },
        {
          key: "Mitsubishi",
          label: "Mitsubishi"
        },
        {
          key: "Mobility Ventures",
          label: "Mobility Ventures"
        },
        {
          key: "Morgan",
          label: "Morgan"
        },
        {
          key: "Morris",
          label: "Morris"
        },
        {
          key: "Moskvitch",
          label: "Moskvitch"
        },
        {
          key: "Nash",
          label: "Nash"
        },
        {
          key: "Nissan",
          label: "Nissan"
        },
        {
          key: "Noble",
          label: "Noble"
        },
        {
          key: "Oakland",
          label: "Oakland"
        },
        {
          key: "Oldsmobile",
          label: "Oldsmobile"
        },
        {
          key: "Opel",
          label: "Opel"
        },
        {
          key: "Packard",
          label: "Packard"
        },
        {
          key: "Pagani",
          label: "Pagani"
        },
        {
          key: "Panoz",
          label: "Panoz"
        },
        {
          key: "Peel",
          label: "Peel"
        },
        {
          key: "Perodua",
          label: "Perodua"
        },
        {
          key: "Peugeot",
          label: "Peugeot"
        },
        {
          key: "Pierce-Arrow",
          label: "Pierce-Arrow"
        },
        {
          key: "Pininfarina",
          label: "Pininfarina"
        },
        {
          key: "Plymouth",
          label: "Plymouth"
        },
        {
          key: "Polestar",
          label: "Polestar"
        },
        {
          key: "Pontiac",
          label: "Pontiac"
        },
        {
          key: "Porsche",
          label: "Porsche"
        },
        {
          key: "Proton",
          label: "Proton"
        },
        {
          key: "Qvale",
          label: "Qvale"
        },
        {
          key: "RAM",
          label: "RAM"
        },
        {
          key: "Rambler",
          label: "Rambler"
        },
        {
          key: "Reliant",
          label: "Reliant"
        },
        {
          key: "Renault",
          label: "Renault"
        },
        {
          key: "REO",
          label: "REO"
        },
        {
          key: "Rivian",
          label: "Rivian"
        },
        {
          key: "Rolls-Royce",
          label: "Rolls-Royce"
        },
        {
          key: "Rover",
          label: "Rover"
        },
        {
          key: "Saab",
          label: "Saab"
        },
        {
          key: "Saleen",
          label: "Saleen"
        },
        {
          key: "Saturn",
          label: "Saturn"
        },
        {
          key: "Saxon",
          label: "Saxon"
        },
        {
          key: "Scion",
          label: "Scion"
        },
        {
          key: "Seat",
          label: "Seat"
        },
        {
          key: "Shelby",
          label: "Shelby"
        },
        {
          key: "Siata",
          label: "Siata"
        },
        {
          key: "Simca",
          label: "Simca"
        },
        {
          key: "Skoda",
          label: "Skoda"
        },
        {
          key: "smart",
          label: "smart"
        },
        {
          key: "Spyker",
          label: "Spyker"
        },
        {
          key: "SRT",
          label: "SRT"
        },
        {
          key: "SSC",
          label: "SSC"
        },
        {
          key: "Sterling",
          label: "Sterling"
        },
        {
          key: "Steyr-Daimler-Puch",
          label: "Steyr-Daimler-Puch"
        },
        {
          key: "Studebaker",
          label: "Studebaker"
        },
        {
          key: "Stutz",
          label: "Stutz"
        },
        {
          key: "Subaru",
          label: "Subaru"
        },
        {
          key: "Sunbeam",
          label: "Sunbeam"
        },
        {
          key: "Suzuki",
          label: "Suzuki"
        },
        {
          key: "Swallow",
          label: "Swallow"
        },
        {
          key: "Talbot",
          label: "Talbot"
        },
        {
          key: "Tesla",
          label: "Tesla"
        },
        {
          key: "Toyota",
          label: "Toyota"
        },
        {
          key: "Trabant",
          label: "Trabant"
        },
        {
          key: "Triumph",
          label: "Triumph"
        },
        {
          key: "Tucker",
          label: "Tucker"
        },
        {
          key: "TVR",
          label: "TVR"
        },
        {
          key: "Ultima",
          label: "Ultima"
        },
        {
          key: "Valiant",
          label: "Valiant"
        },
        {
          key: "Vanden Plas",
          label: "Vanden Plas"
        },
        {
          key: "Vauxhall",
          label: "Vauxhall"
        },
        {
          key: "Vector",
          label: "Vector"
        },
        {
          key: "Volkswagen",
          label: "Volkswagen"
        },
        {
          key: "Volvo",
          label: "Volvo"
        },
        {
          key: "VPG",
          label: "VPG"
        },
        {
          key: "Wagoneer",
          label: "Wagoneer"
        },
        {
          key: "Wartburg",
          label: "Wartburg"
        },
        {
          key: "Wiesmann",
          label: "Wiesmann"
        },
        {
          key: "Willys",
          label: "Willys"
        },
        {
          key: "Yugo",
          label: "Yugo"
        },
        {
          key: "Zastava",
          label: "Zastava"
        },
        {
          key: "Zenvo",
          label: "Zenvo"
        }
      ],
    },
  },
  {
    id: 'model',
    label: 'Model',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_model'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple labels when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // key is the option you see in Flex Console.
      // label is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [{
        key: "other",
        label: "Other"
      }],
    },
  },
  {
    id: 'condition',
    label: 'Condition',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_condition'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple labels when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // key is the option you see in Flex Console.
      // label is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'new', label: 'New' },
        { key: 'used', label: 'Used' },
        { key: 'rebuilt', label: 'Rebuilt/Refurbished' },
        { key: 'broken-re', label: 'Broken/Repairable' },
        { key: 'broken-un', label: 'Broken/Unrepairable' },
      ],
    },
  },
  {
    id: 'delivery',
    label: 'Delivery Method',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_deliverySearch'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple labels when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'multi-enum',

      // key is the option you see in Flex Console.
      // label is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'pickup', label: 'Pick up' },
        { key: 'shipping', label: 'Ship' },
        { key: 'noShipping', label: 'Free Shipping' },
      ],
    },
  },
  // {
  //   id: 'keyword',
  //   label: 'Keyword',
  //   type: 'KeywordFilter',
  //   group: 'primary',
  //   // Note: KeywordFilter is fixed filter,
  //   // you can't change "queryParamNames: ['keywords'],"
  //   queryParamNames: ['keywords'],
  //   // NOTE: If you are ordering search results by distance
  //   // the keyword search can't be used at the same time.
  //   // You can turn on/off ordering by distance from config.js file.
  //   config: {},
  // },

  // Here is an example of multi-enum search filter.
  //
  // {
  //   id: 'amenities',
  //   label: 'Amenities',
  //   type: 'SelectMultipleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_amenities'],
  //   config: {
  //     // Schema type options: 'enum', 'multi-enum'
  //     // Both types can work so that user selects multiple labels when filtering search results.
  //     // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
  //     // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
  //     schemaType: 'multi-enum',

  //     // Optional modes: 'has_all', 'has_any'
  //     // Note: this is relevant only for schema type 'multi-enum'
  //     // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
  //     searchMode: 'has_all',

  //     // key is the option you see in Flex Console.
  //     // label is set here for this web app's UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [
  //       { key: 'towels', label: 'Towels' },
  //       { key: 'bathroom', label: 'Bathroom' },
  //       { key: 'swimming_pool', label: 'Swimming pool' },
  //       { key: 'barbeque', label: 'Barbeque' },
  //     ],
  //   },
  // },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Relevance key is used with keywords filter.
  // Keywords filter also sorts results according to relevance.
  relevanceFilter: 'keywords',

  // Keyword filter is sorting the results by relevance.
  // If keyword filter is active, one might want to disable other sorting options
  // by adding 'keyword' to this list.
  conflictingFilters: [],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};

export const listing = {
  // These should be listing details from public data with schema type: enum
  // SectionDetailsMaybe component shows these on listing page.
  enumFieldDetails: ['size', 'brand', 'category'],
};
