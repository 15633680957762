import React, { useState, useEffect } from 'react';
import css from './ListSlider.module.css';
import {
  ListingCard,
  IconNextArrow,
  IconPrevArrow,
  SkeletonLoader,
  NamedLink,
} from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import PropTypes from 'prop-types';
import ItemsCarousel from 'react-items-carousel';
import classNames from 'classnames';
import SingleLoader from './SingleLoader';
import {isMobile} from 'react-device-detect';
const LARGE_WIDTH = 1921;
const Md_LARGE_WIDTH = 1366;
const MAX_IMAGE_LAYOUT_SCREEN_WIDTH = 1265;
const MAX_IMAGE_TAB_SCREEN_WIDTH = 992;
const TAB_WIDTH = 767;
const MOBILE_WIDTH = 767;
const MAX_IMAGE_MOBILE_SCREEN_WIDTH = 767;
const XSMOBILE_WIDTH = 480;

const ListSlider = props => {
  const next = () => {
    const activeIndex = activeItemIndex;
    const total = props.listings.length;
    if (activeIndex == total) {
      return;
    } else {
      setActiveSlide(activeIndex + 1);
    }
  };

  const previous = () => {
    const activeIndex = activeItemIndex;
    const slideToShow = slidesToShow;
    const total = props.listings.length;
    if (activeIndex == 0) {
      return;
    } else if (activeIndex > total - slideToShow + 1) {
      setActiveSlide(total - slideToShow - 1);
    } else {
      setActiveSlide(activeIndex - 1);
    }
  };

  const setActiveSlide = index => {
    const total = props.listings.length;
    if (index >= 0 && index < total) {
      setActiveItemIndex(index);
    }
  };

  const {
    title,
    listings,
    queryInProgress,
    queryError,
    intl,
    viewport,
    titlePostfix,
    isAuthors,
    authorsData,
    popularCategories,
    isCategories,
    isPopular,
    popularMakes,
    isLocations,
    popularLocations,
    popularParts,
    isParts,
    bookmarks,
    isAuthenticated,
    handleWishlist,
    recentlyAddedSection,
  } = props;

  let listingsAreLoaded = !queryInProgress;

  let isImageLargeLayout = viewport.width > 0 && viewport.width < LARGE_WIDTH;
  let isImageMdLargeLayout =
    viewport.width > MAX_IMAGE_LAYOUT_SCREEN_WIDTH &&
    viewport.width < Md_LARGE_WIDTH;
  let isImageTabLayout =
    viewport.width >= TAB_WIDTH &&
    viewport.width <= MAX_IMAGE_LAYOUT_SCREEN_WIDTH;
  let isImageMMobileLayout =
    viewport.width > 0 && viewport.width <= MOBILE_WIDTH;
  let isImageXSMobileLayout =
    viewport.width > 0 && viewport.width < XSMOBILE_WIDTH;
  let isMobileLayout = isMobile;
  let isTabMobileLayout =
    viewport.width > MAX_IMAGE_MOBILE_SCREEN_WIDTH &&
    viewport.width < MAX_IMAGE_TAB_SCREEN_WIDTH;
  const isImageMobileLayout = viewport.width > 0 && viewport.width < TAB_WIDTH;

  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [slidesToShow, setslidesToShow] = useState(
    isImageXSMobileLayout
      ? 1
      : isImageMMobileLayout
      ? 2
      : isImageTabLayout
      ? 3
      : isImageMdLargeLayout
      ? 4
      : isImageLargeLayout
      ? 4
      : 4
  );

  useEffect(() => {
    isImageLargeLayout = viewport.width > 0 && viewport.width < LARGE_WIDTH;
    isImageMdLargeLayout =
      viewport.width > MAX_IMAGE_LAYOUT_SCREEN_WIDTH &&
      viewport.width < Md_LARGE_WIDTH;
    isImageTabLayout =
      viewport.width > TAB_WIDTH &&
      viewport.width <= MAX_IMAGE_LAYOUT_SCREEN_WIDTH;
    isImageMMobileLayout = viewport.width > 0 && viewport.width < MOBILE_WIDTH;
    isImageXSMobileLayout =
      viewport.width > 0 && viewport.width < XSMOBILE_WIDTH;
    setslidesToShow(
      isImageXSMobileLayout
        ? 1
        : isImageMMobileLayout
        ? 2
        : isImageTabLayout
        ? 3
        : isImageMdLargeLayout
        ? 4
        : isImageLargeLayout
        ? 4
        : 4
    );
  }, [viewport.width]);

  const slideChange = event => {
    if (isImageTabLayout) {
      setActiveItemIndex(event);
    }
  };

  const loadingResults = queryInProgress ? (
    <SkeletonLoader
      viewport={viewport}
      isMobile={isImageMobileLayout}
      noOfSlides={slidesToShow}
    />
  ) : null;

  const queryErrorMessage = queryError ? (
    <h2 className={css.error}>
      <FormattedMessage id="ListSlider.queryError" />
    </h2>
  ) : null;

  const noResults =
    listingsAreLoaded && listings.length === 0 ? (
      <div style={{ display: 'flex', width: '100%', marginBottom: '60px' }}>
        {Array(slidesToShow)
          .fill('')
          .map((item, i) => (
            <SkeletonLoader
              viewport={viewport}
              isMobile={isImageMobileLayout}
              noOfSlides={slidesToShow}
              key={i}
            />
          ))}
      </div>
    ) : null;

  const sliderButtons = (
    <div className={css.sliderButtons}>
      <span className={css.iconHolder} onClick={previous}>
        <IconPrevArrow className={css.icon} />
      </span>
      <span className={css.iconHolder} onClick={next}>
        <IconNextArrow className={css.icon} />
      </span>
    </div>
  );

  return (
    <div
      className={classNames(
        css.moreServices,
        props.moreServicesMobPadd,
        css.fullWidth
      )}
    >
      <div
        className={classNames(
          props.moreServicesMobPadd,
          css.moreServicesTitle,
          props.sliderHeading
        )}
      >
        {listingsAreLoaded && listings.length === 0 ? (
          <SingleLoader />
        ) : (
          <h2 className={css.sectionTitle}>
            <FormattedMessage id={title} /> {titlePostfix}{' '}
          </h2>
        )}
        {sliderButtons}
      </div>

      {loadingResults}
      {queryErrorMessage}
      {noResults}
      {isMobile ? (
        <div
          className={classNames(
            props.moreServicesMobPadd,
            css.cardSlider,
            recentlyAddedSection ? css.recentlyAddedSlider : null
          )}
        >
          {isLocations
            ? popularLocations &&
              popularLocations.map((item, index) => (
                <div className={css.cardSliderItem} key={index}>
                  <NamedLink name="SearchPage">
                    <div className={css.imgWrapper}>
                      <img
                        src={item?.image}
                        alt="Profile Image"
                        className={css.categories}
                      />
                    </div>
                    <h2 className={css.cardItemTitle}>{item.name}</h2>
                  </NamedLink>
                </div>
              ))
            : isPopular
            ? popularMakes &&
              popularMakes.map((item, index) => (
                <div
                  className={classNames(
                    css.cardSliderItem,
                    css.cardSliderItemMakes
                  )}
                  key={index}
                >
                  <NamedLink
                    name="SearchPage"
                    to={{ search: `?pub_make=${item.name}` }}
                  >
                    <div className={classNames(css.imgWrapper, css.makesImage)}>
                      <img
                        src={item?.image}
                        alt="Profile Image"
                        className={css.categories}
                      />
                    </div>
                    <h2 className={css.cardItemTitle}>{item.name}</h2>
                  </NamedLink>
                </div>
              ))
            : isCategories
            ? popularCategories &&
              popularCategories.map((item, index) => (
                <div className={css.cardSliderItem} key={index}>
                  <NamedLink
                    name="SearchPage"
                    to={{ search: `pub_category=${item.key}` }}
                  >
                    <div className={css.imgWrapper}>
                      <img
                        src={item?.image}
                        alt="Profile Image"
                        className={css.categories}
                      />
                    </div>
                    <h2 className={css.cardItemTitle}>{item.name}</h2>
                  </NamedLink>
                </div>
              ))
            : isAuthors
            ? authorsData &&
              authorsData.map((item, index) => (
                <div
                  className={classNames(
                    css.cardSliderItem,
                    css.popularDesignerWrapper
                  )}
                  key={index}
                >
                  <NamedLink name="ProfilePage" params={{ id: item?.id }}>
                    <div className={css.popularDesignerImg}>
                      <img
                        src={item?.profileImage}
                        alt="Profile Image"
                        className={css.authors}
                      />
                    </div>
                  </NamedLink>
                </div>
              ))
            : isParts
            ? popularParts &&
              popularParts.map((item, index) => (
                <div className={css.cardSliderItem} key={index}>
                  <NamedLink
                    name="SearchPage"
                    to={{ search: `pub_category=${item.key}` }}
                  >
                    <div className={css.imgWrapper}>
                      <img
                        src={item?.image}
                        alt="Profile Image"
                        className={css.categories}
                      />
                    </div>
                    <h2 className={css.cardItemTitle}>{item.name}</h2>
                  </NamedLink>
                </div>
              ))
            : listings.map(listing => (
                <div
                  className={isMobileLayout ? css.homepage : css.tabletHomepage}
                >
                  <ListingCard
                    key={listing.id.uuid}
                    listing={listing}
                    intl={intl}
                    showLiveBanner={true}
                    isLandingPage={true}
                    profileSliderCardTitle={props.profileSliderCardTitle}
                    profileSliderCardauthorInfo={
                      props.profileSliderCardauthorInfo
                    }
                    profileSliderCardprice={props.profileSliderCardprice}
                    homeMobileSlider={
                      isMobileLayout ? props.homeMobileSlider : ' '
                    }
                    bookmarks={bookmarks}
                    isAuthenticated={isAuthenticated}
                    handleWishlist={handleWishlist}
                  />
                </div>
              ))}
        </div>
      ) : (
        <div className={css.cardSlider}>
          {listings && listings.length > 0 && (
            <ItemsCarousel
              infiniteLoop={true}
              gutter={15}
              disableSwipe={false}
              numberOfCards={slidesToShow}
              slidesToScroll={1}
              showSlither={false}
              firstAndLastGutter={false}
              requestToChangeActive={slideChange}
              activeItemIndex={activeItemIndex}
            >
              {isLocations
                ? popularLocations &&
                  popularLocations.map((item, index) => (
                    <div className={css.cardSliderItem} key={index}>
                      <NamedLink name="SearchPage">
                        <div className={css.imgWrapper}>
                          <img
                            src={item?.image}
                            alt="Profile Image"
                            className={css.categories}
                          />
                        </div>
                        <h2 className={css.cardItemTitle}>{item.name}</h2>
                      </NamedLink>
                    </div>
                  ))
                : isPopular
                ? popularMakes &&
                  popularMakes.map((item, index) => (
                    <div
                      className={classNames(
                        css.cardSliderItem,
                        css.cardSliderItemMakes
                      )}
                      key={index}
                    >
                      <NamedLink
                        name="SearchPage"
                        to={{ search: `?pub_make=${item.name}` }}
                      >
                         <div className={classNames(css.imgWrapper, css.makesImage)}>
                          <img
                            src={item?.image}
                            alt="Profile Image"
                            className={css.categories}
                          />
                        </div>
                        <h2 className={css.cardItemTitle}>{item.name}</h2>
                      </NamedLink>
                    </div>
                  ))
                : isCategories
                ? popularCategories &&
                  popularCategories.map((item, index) => (
                    <div className={css.cardSliderItem} key={index}>
                      <NamedLink
                        name="SearchPage"
                        to={{ search: `?pub_category=${item.key}` }}
                      >
                        <div className={css.imgWrapper}>
                          <img
                            src={item?.image}
                            alt="Profile Image"
                            className={css.categories}
                          />
                        </div>
                        <h2 className={css.cardItemTitle}>{item.name}</h2>
                      </NamedLink>
                    </div>
                  ))
                : isAuthors
                ? authorsData &&
                  authorsData.map((item, index) => (
                    <div
                      className={classNames(
                        css.cardSliderItem,
                        css.popularDesignerWrapper
                      )}
                      key={index}
                    >
                      <NamedLink name="ProfilePage" params={{ id: item?.id }}>
                        <div className={css.popularDesignerImg}>
                          <img
                            src={item?.profileImage}
                            alt="Profile Image"
                            className={css.authors}
                          />
                        </div>
                      </NamedLink>
                    </div>
                  ))
                : isParts
                ? popularParts &&
                  popularParts.map((item, index) => (
                    <div className={css.cardSliderItem} key={index}>
                      <NamedLink
                        name="SearchPage"
                        to={{ search: `pub_category=${item.key}` }}
                      >
                        <div className={css.imgWrapper}>
                          <img
                            src={item?.image}
                            alt="Profile Image"
                            className={css.categories}
                          />
                        </div>
                        <h2 className={css.cardItemTitle}>{item.name}</h2>
                      </NamedLink>
                    </div>
                  ))
                : listings.map(listing => (
                    <ListingCard
                      key={listing.id.uuid}
                      listing={listing}
                      intl={intl}
                      showLiveBanner={true}
                      isLandingPage={true}
                      profileSliderCardTitle={props.profileSliderCardTitle}
                      profileSliderCardauthorInfo={
                        props.profileSliderCardauthorInfo
                      }
                      profileSliderCardprice={props.profileSliderCardprice}
                      profileSliderCardWidth={props.profileSliderCardWidth}
                      bookmarks={bookmarks}
                      isAuthenticated={isAuthenticated}
                      handleWishlist={handleWishlist}
                    />
                  ))}
            </ItemsCarousel>
          )}
        </div>
      )}
    </div>
  );
};

ListSlider.defaultProps = {
  title: null,
  listings: [],
  queryInProgress: false,
  queryError: null,
};

const { arrayOf, bool, func, object, shape, string } = PropTypes;

ListSlider.propTypes = {
  listings: arrayOf(propTypes.listing),
  queryInProgress: bool.isRequired,
  title: string.isRequired,
};
export default ListSlider;
