import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  Logo,
  ExternalLink,
  NamedLink,
} from '../../components';

import css from './Footer.module.css';
import { withViewport } from '../../util/contextHelpers';
import { compose } from 'redux';

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink
      key="linkToFacebook"
      href={siteFacebookPage}
      className={classNames(css.rightLinks, css.icon)}
      title={goToFb}
    >
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;

  const twitterLink = siteTwitterPage ? (
    <ExternalLink
      key="linkToTwitter"
      href={siteTwitterPage}
      className={classNames(css.rightLinks, css.twitter, css.icon)}
      title={goToTwitter}
    >
      <IconSocialMediaTwitter />
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={classNames(css.rightLinks, css.icon)}
      title={goToInsta}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;
  return [fbLink, twitterLink, instragramLink].filter(v => v != null);
};

const FooterComponent = props => {
  const { rootClassName, className, intl, viewport } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);

  const isMobileLayout =
  viewport.width > 0 && viewport.width <= 767;

  if (isMobileLayout) {
    return (
      <div className={classes}>
        <div className={css.mobileContent}>
          <div className={css.list}>
            <span className={css.listItem}>
              <NamedLink name="AboutPage" className={css.link}>
                <FormattedMessage id="Footer.toAboutPage" />
              </NamedLink>
            </span>
            <span className={css.listItem}>
              <NamedLink
                name="AboutPage"
                to={{ hash: '#contact' }}
                className={css.link}
              >
                <FormattedMessage id="Footer.toContactPage" />
              </NamedLink>
            </span>
            <span className={css.listItem} >
              <NamedLink name="TermsOfServicePage" className={css.link}>
                <FormattedMessage id="Footer.termsOfUse" />
              </NamedLink>
            </span>
            <span className={css.listItem}>
              <NamedLink name="FAQPage" className={css.link}>
                <FormattedMessage id="Footer.toFAQPage" />
              </NamedLink>
            </span>
          </div>
          <div className={css.extraLinks}>
            <div className={css.socialLinks}>{socialMediaLinks}</div>
            <div className={css.copyrightLinks}>
              <NamedLink name="LandingPage">
                <Logo
                  format="desktop"
                  className={classNames(css.rightLinks, css.logo)}
                />
              </NamedLink>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={classes}>
      <div className={css.content}>
        <ul className={css.list}>
          <li className={css.listItem}>
            <NamedLink name="AboutPage" className={css.link}>
              <FormattedMessage id="Footer.toAboutPage" />
            </NamedLink>
          </li>
          <li className={css.listItem}>
            <NamedLink name="FAQPage" className={css.link}>
              <FormattedMessage id="Footer.toFAQPage" />
            </NamedLink>
          </li>
          {/* <li className={css.listItem}>
            <NamedLink name="LandingPage" className={css.link}>
              <FormattedMessage id="Footer.toHelpPage" />
            </NamedLink>
          </li> */}
          <li className={css.listItem}>
            <NamedLink
              name="AboutPage"
              to={{ hash: '#contact' }}
              className={css.link}
            >
              <FormattedMessage id="Footer.toContactPage" />
            </NamedLink>
          </li>
          <li className={css.listItem}>
            <NamedLink name="TermsOfServicePage" className={css.link}>
              <FormattedMessage id="Footer.termsOfUse" />
            </NamedLink>
          </li>
          <li className={css.listItem}>
            <NamedLink name="PrivacyPolicyPage" className={css.link}>
              <FormattedMessage id="Footer.privacyPolicy" />
            </NamedLink>
          </li>
        </ul>
        <div className={css.extraLinks}>
          <div className={css.socialLinks}>{socialMediaLinks}</div>
          <div className={css.copyrightLinks}>
          <NamedLink name="LandingPage">
            <Logo
              format="desktop"
              className={classNames(css.rightLinks, css.logo)}
            />
          </NamedLink>
            {/* <span className={css.rightLinks}>
              <FormattedMessage id="Footer.copyright" />
            </span> */}
          </div>
        </div>
      </div>
    </div>
  );
};

FooterComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

FooterComponent.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

const Footer = compose(
  withViewport,
  injectIntl
)(FooterComponent);

export default injectIntl(Footer);
