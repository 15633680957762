import { PayPalApi, sendSms } from '../util/api';
import { storableError } from '../util/errors';
import * as log from '../util/log';

// ================ Action types ================ //

export const ONBOARDING_LINK_REQUEST = 'app/paypal/ONBOARDING_LINK_REQUEST';
export const ONBOARDING_LINK_SUCCESS = 'app/paypal/ONBOARDING_LINK_SUCCESS';
export const ONBOARDING_LINK_ERROR = 'app/paypal/ONBOARDING_LINK_ERROR';

export const MERCHANT_SIGNUP_STATUS_REQUEST = 'app/paypal/MERCHANT_SIGNUP_STATUS_REQUEST';
export const MERCHANT_SIGNUP_STATUS_SUCCESS = 'app/paypal/MERCHANT_SIGNUP_STATUS_SUCCESS';
export const MERCHANT_SIGNUP_STATUS_ERROR = 'app/paypal/MERCHANT_SIGNUP_STATUS_ERROR';

export const CREATE_ORDER_REQUEST = 'app/paypal/CREATE_ORDER_REQUEST';
export const CREATE_ORDER_SUCCESS = 'app/paypal/CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_ERROR = 'app/paypal/CREATE_ORDER_ERROR';

export const CAPTURE_ORDER_REQUEST = 'app/paypal/CAPTURE_ORDER_REQUEST';
export const CAPTURE_ORDER_SUCCESS = 'app/paypal/CAPTURE_ORDER_SUCCESS';
export const CAPTURE_ORDER_ERROR = 'app/paypal/CAPTURE_ORDER_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchOnboardingLinkInProgress: false,
  fetchOnboardingLinkError: null,
  onboardingLink: null,
  fetchPaypalMerchantInProgress: false,
  fetchPaypalMerchantError: null,
  paypalMerchant: null,
  createOrderInProgress: false,
  createOrderError: null,
  paypalOrderData: null,
  captureOrderInProgress: false,
  captureOrderError: null,
  paypalCaptureData: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case ONBOARDING_LINK_REQUEST:
      return { ...state, fetchOnboardingLinkError: null, fetchOnboardingLinkInProgress: true };
    case ONBOARDING_LINK_SUCCESS:
      return {
        ...state,
        fetchOnboardingLinkInProgress: false,
        onboardingLink: payload,
      };
    case ONBOARDING_LINK_ERROR:
      console.error(payload);
      return {
        ...state,
        fetchOnboardingLinkError: payload,
        fetchOnboardingLinkInProgress: false,
      };

    case MERCHANT_SIGNUP_STATUS_REQUEST:
      return { ...state, fetchPaypalMerchantError: null, fetchPaypalMerchantInProgress: true };
    case MERCHANT_SIGNUP_STATUS_SUCCESS:
      return {
        ...state,
        fetchPaypalMerchantInProgress: false,
        paypalMerchant: payload,
      };
    case MERCHANT_SIGNUP_STATUS_ERROR:
      console.error(payload);
      return {
        ...state,
        fetchPaypalMerchantError: payload,
        fetchPaypalMerchantInProgress: false,
      };

    case CREATE_ORDER_REQUEST:
      return { ...state, createOrderError: null, createOrderInProgress: true };
    case CREATE_ORDER_SUCCESS:
      return {
        ...state,
        createOrderInProgress: false,
        paypalOrderData: payload,
      };
    case CREATE_ORDER_ERROR:
      console.error(payload);
      return {
        ...state,
        createOrderError: payload,
        createOrderInProgress: false,
      };

    case CAPTURE_ORDER_REQUEST:
      return { ...state, captureOrderError: null, captureOrderInProgress: true };
    case CAPTURE_ORDER_SUCCESS:
      return {
        ...state,
        captureOrderInProgress: false,
        paypalOrderData: payload,
      };
    case CAPTURE_ORDER_ERROR:
      console.error(payload);
      return {
        ...state,
        captureOrderError: payload,
        captureOrderInProgress: false,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const onboardingLinkRequest = () => ({ type: ONBOARDING_LINK_REQUEST });

export const onboardingLinkSuccess = payload => ({
  type: ONBOARDING_LINK_SUCCESS,
  payload,
});

export const onboardingLinkError = e => ({
  type: ONBOARDING_LINK_ERROR,
  payload: e,
  error: true,
});

export const merchantSignupStatusRequest = () => ({
  type: MERCHANT_SIGNUP_STATUS_REQUEST,
});

export const merchantSignupStatusSuccess = payload => ({
  type: MERCHANT_SIGNUP_STATUS_SUCCESS,
  payload,
});

export const merchantSignupStatusError = e => ({
  type: MERCHANT_SIGNUP_STATUS_ERROR,
  payload: e,
  error: true,
});

export const createOrderRequest = () => ({
  type: CREATE_ORDER_REQUEST,
});

export const createOrderSuccess = payload => ({
  type: CREATE_ORDER_SUCCESS,
  payload,
});

export const createOrderError = e => ({
  type: CREATE_ORDER_ERROR,
  payload: e,
  error: true,
});

export const captureOrderRequest = () => ({
  type: CAPTURE_ORDER_REQUEST,
});

export const captureOrderSuccess = payload => ({
  type: CAPTURE_ORDER_SUCCESS,
  payload,
});

export const captureOrderError = e => ({
  type: CAPTURE_ORDER_ERROR,
  payload: e,
  error: true,
});

// ================ Thunks ================ //

export const fetchPaypalOnBoardingLink = () => (dispatch, getState, sdk) => {
  dispatch(onboardingLinkRequest());

  return PayPalApi.getOnboardingLink()
    .then(response => {
      const onboardingLink = response.links.find(l => l.rel === 'action_url');
      dispatch(onboardingLinkSuccess(onboardingLink));

      return onboardingLink;
    })
    .catch(err => {
      const e = storableError(err);
      dispatch(onboardingLinkError(e));
      log.error(err, 'fetch-paypal-onboarding-links-failed');
    });
};

export const fetchPaypalMerchantSignupStatus = () => (dispatch, getState, sdk) => {
  dispatch(merchantSignupStatusRequest());

  return PayPalApi.getMerchantOnboardingStatus()
    .then(response => {
      dispatch(merchantSignupStatusSuccess(response));
      return response;
    })
    .catch(err => {
      const e = storableError(err);
      dispatch(merchantSignupStatusError(e));
      log.error(err, 'fetch-paypal-merchant-status-failed');
    });
};

export const createOrder = orderParams => (dispatch, getState, sdk) => {
  dispatch(createOrderRequest());

  return PayPalApi.createOrder(orderParams)
    .then(response => {
      dispatch(createOrderSuccess(response));
      return response.id;
    })
    .catch(err => {
      const e = storableError(err);
      dispatch(createOrderError(e));
      log.error(err, 'create-paypal-order-failed', e);
    });
};

export const captureOrder = (orderId, smsParams) => (dispatch, getState, sdk) => {
  dispatch(captureOrderRequest());

  return PayPalApi.captureOrder({ orderId })
    .then(response => {
      dispatch(captureOrderSuccess(response));
      const phoneNumber = smsParams && smsParams.phoneNumber;
      if (phoneNumber) {
        sendSms(smsParams)
      };
      return response;
    })
    .catch(err => {
      const e = storableError(err);
      dispatch(captureOrderError(e));
      log.error(err, 'capture-paypal-order-failed', e);
    });
};

export const completeOrder = (orderId, smsParams, partnerFee) => (dispatch, getState, sdk) => {
  dispatch(captureOrderRequest());
  return PayPalApi.completeOrder({ orderId, partnerFee })
    .then(response => {
      dispatch(captureOrderSuccess(response));
      const phoneNumber = smsParams && smsParams.phoneNumber;
      if (phoneNumber) {
        sendSms(smsParams)
      };
      return response;
    })
    .catch(err => {
      const e = storableError(err);
      dispatch(captureOrderError(e));
      log.error(err, 'capture-paypal-order-failed', e);
    });
};
