import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.module.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Effective Date: July 30th, 2022</p>

      <p>
        This Privacy Policy describes the policies and procedures of PartOut (“PartOut,” “we”, “our” or “us”) on the collection, use and disclosure of your information on www.PartOut.com (the “Site”) and the services, features, content or applications we offer (collectively with the Site, the “Services”). We receive information about you from various sources, including: (i) if you register for the Site and the Services, through your user account on the Services (your “Account”); (ii) your use of the Services generally; and (iii) from third party websites and services. When you use the Services, you are consenting to the collection, transfer, manipulation, storage, disclosure and other uses of your information as described in this Privacy Policy. Any terms used but not defined in this Privacy Policy have the meanings set forth in our Terms of Service, available at www.partout.com/terms-of-service.
      </p>

      <h2>What Does This Privacy Policy Cover?</h2>
      <p>
        This Privacy Policy covers the treatment of personally identifiable information (“Personal Information”) gathered when you are using or accessing the Services. This Privacy Policy also covers our treatment of any Personal Information that our business partners share with us or that we share with our business partners.
        <br />
        <br />
        This Privacy Policy does not apply to the practices of third parties that we do not own or control, including but not limited to any third party websites, services and applications (“Third Party Services”) that you elect to access through the Service, including our Payment Processor, or to individuals that we do not manage or employ. While we attempt to facilitate access only to those Third Party Services that share our respect for your privacy, we cannot take responsibility for the content or privacy policies of those Third Party Services. We encourage you to carefully review the privacy policies of any Third Party Services you access.
      </p>

      <h2>What Information Do We Collect?</h2>
      <p>
        The information we gather enables us to personalize, improve and continue to operate the Services. In connection with certain aspects of the Services, we may request, collect and/or display some of your Personal Information. We collect the following types of information from our users.
        <br />
        <br />
        <b>Account Information:</b> When you create an Account, you will provide information that could be Personal Information, such as your username, password, phone number and email address. You acknowledge that this information may be personal to you, and by creating an account on the Services and providing Personal Information to us, you allow others, including us, to identify you and therefore may not be anonymous. We may use your contact information to send you information about our Services, but only rarely when we feel such information is important. You may unsubscribe from these messages through your Account settings, although we, regardless, reserve the right to contact you when we believe it is necessary, such as for account recovery purposes.
        <br />
        <br />
        <b>User Content:</b> Some features of the Services allow you to provide content to the Services, such as written comments. All content submitted by you to the Services may be retained by us indefinitely, even after you terminate your account. We may continue to disclose such content to third parties in a manner that does not reveal Personal Information, as described in this Privacy Policy.
        <br />
        <br />
        <b>User Contact Information:</b> You may choose to provide, and will provide if you become a Winning Buyer, information that could be Personal Information, such as your name, mailing address, phone number and email address. You acknowledge that this information may be personal to you, and by providing Personal Information to us, you allow others, including us and applicable Sellers, to identify you, and therefore may not be anonymous.
        <br />
        <br />
        <b>Financial Information: </b>We do not currently collect financial information, such as your payment method (valid credit card number, type, expiration date or other financial information); that information is collected and stored by our third party payment processing company (the “Payment Processor”), and use and storage of that information is governed by the Payment Processor’s applicable terms of service and privacy policy.
        <br />
        <br />
        <b>IP Address Information and Other Information Collected Automatically:</b>We automatically receive and record information from your web browser when you interact with the Services, including your IP address and cookie information. This information is used for fighting spam/malware and also to facilitate collection of data concerning your interaction with the Services (e.g., what links you have clicked on).
        <br />
        <br />
        Generally, the Services automatically collect usage information, such as the number and frequency of visitors to the Site. We may use this data in aggregate form, that is, as a statistical measure, but not in a manner that would identify you personally. This type of aggregate data enables us and third parties authorized by us to figure out how often individuals use parts of the Services so that we can analyze and improve them.
        <br />
        <br />
        <b>Email Communications:</b> We may receive a confirmation when you open an email from us. We use this confirmation to improve our customer service.
        <br />
        <br />
        <b>SMS Communications: </b>This service is used to send push notifications of major events to users such as, but not limited to, items being sold, bought, and general inquiries.
        <br />
        <br />
        <b>Information Collected Using Cookies:</b> Cookies are pieces of text that may be provided to your computer through your web browser when you access a website. Your browser stores cookies in a manner associated with each website you visit. We use cookies to enable our servers to recognize your web browser and tell us how and when you visit the Site and otherwise use the Services through the Internet.
        Our cookies do not, by themselves, contain Personal Information, and we do not combine the general information collected through cookies with other Personal Information to tell us who you are. As noted, however, we do use cookies to identify that your web browser has accessed aspects of the Services and may associate that information with your Account if you have one.
        <br />
        <br />
        Most browsers have an option for turning off the cookie feature, which will prevent your browser from accepting new cookies, as well as (depending on the sophistication of your browser software) allowing you to decide on acceptance of each new cookie in a variety of ways. We strongly recommend that you leave cookies active, because they enable you to take advantage the most attractive features of the Services.
        <br />
        <br />
        This Privacy Policy covers our use of cookies only and does not cover the use of cookies by third parties. We do not control when or how third parties place cookies on your computer. For example, third party websites to which a link points may set cookies on your computer.
        <br />
        <br />
        <b>Aggregate Information: </b>We collect statistical information about how both unregistered and registered users, collectively, use the Services (“Aggregate Information”). Some of this information is derived from Personal Information. This statistical information is not Personal Information and cannot be tied back to you, your Account or your web browser.
        <br />
        <br />
        <b>Information Regarding Your Social Networks: </b>Occasionally, you can use your Account on our Services to interact with your accounts on other services, such as Facebook or Twitter. You can access posting and sharing tools on the Services, including a “share” button that allows you to post information to your social networks outside of the Services (“Share”). For example, after posting a Listing on the Services, you can Share information about that Listing with your Facebook friends or Twitter followers. Please note that these tools may be operated by Third Party Services.
        <br />
        <br />
        By using these tools, you acknowledge that the Third Party Service that operates the tool may be collecting information about your browser or online activity through its own tracking technologies and subject to its own privacy policy. When you use these tools, some of your information from the Services (such as the Listing information you selected to Share) may be shared with the Third Party Service and others. Therefore, we encourage you to read the privacy policies and other policies of the social networks you use in connection with the Services.
        <br />
        <br />
      </p>

      <h2>How, and With Whom, Is My Information Shared?</h2>
      <p>
        The Services are designed to help you share information with others. As a result, some of the information generated through the Services is shared publicly or with third parties. Consistent with the permissions you give us to collect the information, we may use the information we collect, including your personal information:
        <br />
        <br />
        1. to provide, personalize, and improve your experience with the Service and products, services, and advertising (including for third party products and services) made available on or outside the Service (including on other sites that you visit), for example by providing customized, personalized, or localized products, recommendations, features, and advertising on or outside of the Service;
        <br />
        <br />
        2. to ensure technical functionality of the Service, develop new products and services, and analyse your use of the Service, including your interaction with applications, advertising, products, and services that are made available, linked to, or offered through the Service;
        <br />
        <br />
        3. to communicate with you for Service-related or research purposes including via emails, notifications, text messages, or other messages, which you agree to receive;
        <br />
        <br />
        4. to communicate with you, either directly or through one of our partners, for marketing and promotional purposes via emails, notifications, or other messages, consistent with any permissions you may have communicated to us (e.g., through your account settings); in the preferences section, we inform you of the controls you can use to opt out from receiving certain messages;
        <br />
        <br />
        5. to process your payment or prevent or detect fraud;
        <br />
        <br />
        6. to create a list of actual and prospective users and subscribers for our products or Services.
        <br />
        <br />
        7. to assemble statistics regarding the use of our Websites, product or Services.
        <br />
        <br />
        8. to process your payment or prevent fraud and abuse detect fraud
        <br />
        <br />
        9. to comply with any legal or regulatory obligations.
        <br />
        <br />
        10. to enforce this Privacy Policy, the Terms and Conditions of Use, and any other terms that you have agreed to, including to protect the rights, property, or safety of PartOut, its users, or any other person, or the copyright-protected content of the Service;
        <br />
        <br />
        11. and as otherwise stated in this Privacy Policy.
      </p>

      <p>
        <b>Public Information About Your Activity on the Services:</b> Some of your activity on and through the Services is public by default. This may include, but is not limited to, content you have posted publicly on the Site or otherwise through the Services.
        <br />
        <br />
        Registered users may have some of this information associated with their Accounts. Unregistered users will not have this association, but information concerning their use of the Services (such as what pages they have visited) may be tracked anonymously through the use of cookies and stored by us.
        <br />
        <br />
        Please also remember that if you choose to provide Personal Information using certain public features of the Services, then that information is governed by the privacy settings of those particular features and may be publicly available. Individuals reading such information may use or disclose it to other individuals or entities without our control and without your knowledge, and search engines may index that information. We therefore urge you to think carefully about including any specific information you may deem private in content that you create or information that you submit through the Services.
      </p>

      <h2>Service Providers</h2>
      <p>
        We work with third party service providers who provide website, application development, hosting, maintenance, and other services for us. These third parties may have access to, or process personal data as part of providing those services for us. We limit the information provided to these service providers to that which is reasonably necessary for them to perform their functions, and our contracts with them require them to maintain the confidentiality of such information.
      </p>

      <h2>Non-Personally Identifiable Information</h2>
      <p>
        We may make certain automatically-collected, aggregated, or otherwise non-personally-identifiable information available to third parties for various purposes, including (i) compliance with various reporting obligations; (ii) for business or marketing purposes; or (iii) to assist such parties in understanding our Clients’, Users’ and Visitors’ interests, habits, and usage patterns for certain programs, content, services, and/or functionality available through the Service.
      </p>

      <h2>IP Address Information: </h2>
      <p>
        While we collect and store IP address information, that information is not made public. We do at times, however, share this information with our partners, service providers and other persons with whom we conduct business, and as otherwise specified in this Privacy Policy.
      </p>

      <h2>Information You Elect to Share: </h2>
      <p>
        You may access other Third Party Services through the Services, for example by clicking on links to those Third Party Services from within the Site. For example, you may elect to share Personal Information with our Payment Processor for the purposes of processing payments for goods included in applicable Listings. We are not responsible for the privacy policies and/or practices of these Third Party Services, and you are responsible for reading and understanding those Third Party Services’ privacy policies. This Privacy Policy only governs information collected on the Services.
        <br />
        You may elect to share your information with other Grailed users through the Services. We strongly advise you to use the utmost caution before sharing any Personal Information with others, including users. You should not, under any circumstances, provide your financial information (for example, credit card or bank account numbers) to other individuals.
      </p>

      <h2>Aggregate Information: </h2>
      <p>
        We share Aggregate Information with our partners, service providers and other persons with whom we conduct business. We share this type of statistical data so that our partners can understand how and how often people use our Services and their services or websites, which facilitates improving both their services and how our Services interface with them. In addition, these third parties may share with us non-private, aggregated or otherwise non Personal Information about you that they have independently developed or acquired.
      </p>

      <h2>Data Sharing Information </h2>
      <p>
        PartOut may share your personal information with certain third-parties. This sharing is based on our relationship with those third-parties, your consent, or legal or contractual obligations that require us to share such information. We may share your personal information that we collect or you provide as follows:
        <br />
        <br />
        - To our subsidiaries and affiliates for the purposes outlined in this Privacy Policy.
        <br />
        - To contractors, service providers such as Stripe, and other third-parties we use to support our business. These entities are bound by contractual obligations to keep personal information confidential and can use it only for the purposes for which we disclose the information to them.
        <br />
        - To comply with any court order, law, or legal process (such as a subpoena), to investigate fraud, including responding to any government or regulatory request, lawful requests by public authorities, or to meet national security or law enforcement requirements.
        <br />
        - To enforce or apply our Terms of Service and other subscription agreements, including for billing and collection purposes.
        <br />
        - If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of PartOut, our subscribers or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.
        <br />
        - For any other purpose disclosed by us when you provide the information.
        <br />
        - With your consent.
      </p>

      <h2>Data Retention Information</h2>
      <p>PartOut may retain your personal information for a period of time consistent with the original purpose of collection. For instance, we may retain your personal information during the time you have an account to use our Websites or Services, or as agreed in our term agreements, and for a reasonable period of time thereafter. We also may retain your personal information during the period of time needed for PartOut to pursue our legitimate business interests, conduct audits, comply with our legal obligations, resolve disputes, and enforce our agreements.</p>

      <h2>Email Communications with Us:</h2>
      <p>
        As part of the Services, you may occasionally receive email and other communications from us, such as communications relating to your Account. Communications relating to your Account will only be sent for purposes important to the Services, such as password recovery. </p>

      <h2>User Profile Information:</h2>
      <p>
        User profile information including your username and other information you enter may be displayed to other users to facilitate user interaction within the Services. We will not directly reveal user email addresses to other users. </p>

      <h2>Financial Information:</h2>
      <p>
        As stated above, we do not currently collect financial information, as that information is collected and stored by our Payment Processor. However, we may from time to time request and receive some of your financial information from our Payment Processor for the purposes of completing transactions you have initiated through the Services, enrolling you in discount, rebate, and other programs in which you elect to participate, protecting against or identifying possible fraudulent transactions, and otherwise as needed to manage our business.</p>

      <h2>
        Information Shared with Our Agents:</h2>
      <p>
        We employ and contract with people and other entities that perform certain tasks on our behalf and who are under our control (our “Agents”). We may need to share Personal Information with our Agents in order to provide products or services to you. Unless we tell you differently, our Agents do not have any right to use Personal Information or other information we share with them beyond what is necessary to assist us. You hereby consent to our sharing of Personal Information with our Agents.</p>

      <h2>Information Disclosed Pursuant to Business Transfers:</h2>
      <p>
        In some cases, we may choose to buy or sell assets. In these types of transactions, user information is typically one of the transferred business assets. Moreover, if we, or substantially all of our assets, were acquired, or if we go out of business or enter bankruptcy, user information would be one of the assets that is transferred or acquired by a third party. You acknowledge that such transfers may occur, and that any acquirer of us or our assets may continue to use your Personal Information as set forth in this policy.</p>

      <h2>Information Disclosed for Our Protection and the Protection of Others:</h2>
      <p>
        We also reserve the right to access, read, preserve, and disclose any information as we reasonably believe is necessary to (i) satisfy any applicable law, regulation, legal process or governmental request, (ii) enforce this Privacy Policy and our Terms of Service, including investigation of potential violations hereof, (iii) detect, prevent, or otherwise address fraud, security or technical issues, (iv) respond to user support requests, or (v) protect our rights, property or safety, our users and the public. This includes exchanging information with other companies and organizations for fraud protection and spam/malware prevention.</p>

      <h2>Information We Share With Your Consent:</h2>
      <p>
        Except as set forth above, you will be notified when your Personal Information may be shared with third parties, and will be able to prevent the sharing of this information.</p>

      <h2>Is Information About Me Secure?</h2>
      <p>
        Your Account information will be protected by a password for your privacy and security. You need to prevent unauthorized access to your Account and Personal Information by selecting and protecting your password appropriately and limiting access to your computer and browser by signing off after you have finished accessing your Account.
        <br />
        <br />
        We seek to protect Account information to ensure that it is kept private; however, we cannot guarantee the security of any Account information. Unauthorized entry or use, hardware or software failure, and other factors, may compromise the security of user information at any time.
        <br />
        <br />
        We otherwise store all of our information, including your IP address information, using industry-standard techniques. We do not guarantee or warrant that such techniques will prevent unauthorized access to information about you that we store, Personal Information or otherwise.</p>

      <h2>User Data Rights</h2>
      <p>
        By using our Websites, Services, and Marketing Activities you expressly give your consent for the collection and use of your personal information in compliance with this Privacy Policy. This does not affect lawful processing under applicable laws based on other legal basis such as contractual obligation, compliance with applicable laws, and legitimate purposes.</p>

      <h2>Right to Access or Correct Personal Information</h2>
      <p>
        You have the right to access and correct your personal information. If you want to review or correct your personal information, you can login to the appropriate Website or Service and visit your account profile page, or contact us through the Support Center, or you may send us an email at the address noted in the Contact Information section above.</p>

      <h2>Right to Delete Personal Information (“Right to be Forgotten”)</h2>
      <p>
        If PartOut processes your personal information, you have the right to request deletion of personal information we hold about you and we have the obligation to erase your personal information, where:
        <br />
        <br />
        - the personal information is no longer necessary in relation to the purposes for which it was collected or otherwise processed,
        <br />
        - you withdraw consent on which the processing is based and where there is no other legal ground for the processing,
        <br />
        - you object to the processing and there are no overriding legitimate grounds for the processing,
        <br />
        - the personal information has been unlawfully processed, or
        <br />
        - the personal information has to be erased for compliance with a legal obligation, such as the European Union or other Member State, law to which PartOut is subject.
        <br />

        <h2>Right to Data Portability</h2>
        <br />
        You have the right to receive or transfer a copy of your personal information, where:
        <br />
        - we are relying upon your consent or the fact that the processing is necessary for the performance of a contract to which you are party as the legal basis for the processing, and
        <br />
        - personal information is processed by automatic means.
        This copy will be provided to you in a common machine-readable format.
        <br />
        <br />
        If you want to request a copy of your personal information, you can login to the appropriate Website or Service and contact us through the Support Center, or you may send us an email at the address noted in the Contact Information section above.</p>

      <h2>What Information of Mine Can I Access?</h2>
      <p>
        If you are a registered user, you can access information associated with your Account by logging into the Services. Registered and unregistered users can access and delete cookies through their web browser settings.
        <br />
        California Privacy Rights: Under California Civil Code sections 1798.83-1798.84, California residents are entitled to ask us for a notice identifying the categories of personal customer information which we share with our affiliates and/or third parties for marketing purposes, and providing contact information for such affiliates and/or third parties. If you are a California resident and would like a copy of this notice, please submit a written request to the following address: help@partout.com.
        <br />
        If you want to request removal of your personal information from our Websites or Services, you can contact us through the Support Center, or you may send us an email at the address noted in the Contact Information section above. You can also request closure of your account. We will respond to your request within 30 days.
        In some cases, we may not be able to remove your personal information, in which case we will let you know that we are unable to do so and why.
      </p>

      <h2>How Can I Delete My Account?</h2>
      <p>
        Should you ever decide to delete your Account, you may do so by emailing help@partout.com. If you terminate your Account, any association between your Account and information we store will no longer be accessible through your Account. However, given the nature of sharing on the Services, any public activity on your Account prior to deletion will remain stored on our servers and will remain accessible to the public.
      </p>

      <h2>What Choices Do I Have Regarding My Information?</h2>
      <p>
        You can use many of the features of the Services without registering, thereby limiting the type of information that we collect.
        <br />
        You can always opt not to disclose certain information to us, even though it may be needed to take advantage of some of our features.
        <br />
        You can delete your Account. Please note that we will need to verify that you have the authority to delete the Account, and activity generated prior to deletion will remain stored by us and may be publicly accessible.
        <br />
        <h2>What Happens When There Are Changes to this Privacy Policy?</h2>
        <p>
          We may amend this Privacy Policy from time to time. Use of information we collect now is subject to the Privacy Policy in effect at the time such information is used. If we make changes in the way we collect or use information, we will notify you by posting an announcement on the Services or sending you an email. A user is bound by any changes to the Privacy Policy when he or she uses the Services after such changes have been first posted.
        </p>
      </p>
      <h2> If I Have Questions or Concerns</h2>
      <p>
        If you have any questions or concerns regarding privacy using the Services, please send us a detailed message to help@partout.com. We will make every effort to resolve your concerns.
      </p>
      <h2>An Additional Note Regarding Data Processing:</h2>
      <p>
        Your Personal Information may be collected, processed and stored by us or our service providers in the United States and other countries where our servers reside. Please be aware that the privacy protections and legal requirements, including the rights of authorities to access your Personal Information, in some of these countries may not be equivalent to those in your country. If you are using the Services in the European Union or other regions with laws governing data collection and use that may differ from U.S. law, you consent to the transfer of your Personal Information and other information to the United States and other countries where we and our service providers operate.
      </p>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
