import React from 'react';
import css from './TagsComponent.module.css';
import { IconCloseSmall, IconHelp, IconClose, DeleteTagIcon } from '../../components';
import OutsideClickHandler from '../OutsideClickHandler/OutsideClickHandler';

const ENTER_KEY = 13;
const TAB_KEY = 9;
const COMMA_KEY = 188;
const BACKSPACE_KEY = 8;
const MAXTAGS = 10;

class TagsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { tags: this.props.tags, value: '', clicked: false };

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleDeleteAll = this.handleDeleteAll.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  handleDeleteAll() {
    this.setState({
      tags: [],
    });
  }
  handleDelete(tag) {
    const updatedTags = this.state.tags.filter(item => item !== tag);
    this.setState({
      tags: updatedTags,
    });
    this.props.handleDeleteSingle(tag)
  }
  handleChange(e) {
    this.setState({
      value: e.target.value,
    });
  }

  onBlur() {
    this.addTag();
  }

  handleKeyUp(e) {
    const key = e.keyCode;
    if (this.state.tags.length >= MAXTAGS) return false;
    if (key === ENTER_KEY || key === COMMA_KEY ) {
      this.addTag();
    }
  }

  handleKeyDown(e) {
    const key = e.keyCode;
    if(key === TAB_KEY){
      this.addTag();
      e.preventDefault();
    }
    if (key === BACKSPACE_KEY && !this.state.value) {
      this.editPrevTag();
    }
  }

  addTag() {
    const { tags, value } = this.state;
    let tag = value.trim();

    tag = tag.replace(/,/g, '');

    if (!tag) {
      return;
    }

    this.setState({
      tags: [...tags, tag],
      value: '',
    });

    this.props.setTags([...tags, tag]);
  }

  editPrevTag() {
    let { tags } = this.state;

    const tag = tags.pop();

    this.setState({ tags, value: tag });
    this.props.setTags(tags);
  }

  render() {
    const { handleDelete, newTags } = this.props;
    const { tags, value } = this.state;

    return (
      <div className={css.form}>
        <div className={css.ingresosWrapper}>
          <div
            style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
            className={css.labelWrapper}
          >
            <label>Tags</label>
            {/* <button
              type="button"
              onClick={() => this.setState({ clicked: !this.state.clicked })}
              onBlur={() => this.setState({ clicked: false })}
              style={{
                border: 'none',
                cursor: 'pointer',
                margin: '0 0px 0px 6px',
                lineHeight: '100%',
              }}
            >
              <span
                style={
                  this.state.clicked
                    ? {
                        width: '20px',
                        height: '20px',
                        marginLeft: '0px',
                        border: '1px solid #722e92',
                        borderRadius: '50px',
                        padding: '2px',
                        display: 'inline-flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }
                    : {
                        width: '20px',
                        height: '20px',
                        marginLeft: '0px',
                        // border: '1px solid #722e92',
                        borderRadius: '25px',
                        padding: '2px',
                      }
                }
              >
                <IconHelp />
              </span>
            </button> */}
          </div>
          {this.state.clicked && (
            <div className={css.tooltip}>
              <p style={{ fontSize: '14px', margin: '0px' }} className={css.tooltipStyle}>
                Tags allow users to search by tags.
              </p>
              <span
                className={css.toltipCloseBtn}
                style={{ width: '12px', height: '12px', marginLeft: '10px' }}
                onClick={() => this.setState({ clicked: false })}
              >
                <IconCloseSmall />
              </span>
            </div>
          )}
        </div>
        <div className={css.tags}>
          <ul>
            {tags &&
              tags.map((tag, i) => (
                <li key={tag + i} className={css.tag}>
                  <span className={css.tagsItem} onClick={() => this.handleDelete(tag)}>
                    {tag}
                    <DeleteTagIcon />
                  </span>
                </li>
              ))}
            {tags && tags.length > 0 ? (
              <span className={css.deleteAllTags} onClick={this.handleDeleteAll}>
                <IconClose />
              </span>
            ) : null}
          </ul>
          <OutsideClickHandler className={css.fullWidth} onOutsideClick={() => this.onBlur()}>
            <input
              key="tag"
              type="text"
              placeholder="Add tag"
              value={value}
              onChange={this.handleChange}
              ref="tag"
              className={css.tagInput}
              onKeyUp={this.handleKeyUp}
              onKeyDown={this.handleKeyDown}
            />
          </OutsideClickHandler>
        </div>
        {tags.length >= 10 ? <p className={css.error}>Max 10 tags allowed</p> : null}
      </div>
    );
  }
}

export default TagsComponent;
