import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  arrayOf,
  array,
  bool,
  func,
  node,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { UserCard } from '../../components';

import config from '../../config';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import {
  propTypes,
  LISTING_STATE_CLOSED,
  LINE_ITEM_NIGHT,
  LINE_ITEM_DAY,
  LINE_ITEM_UNITS,
} from '../../util/types';
import { formatMoney } from '../../util/currency';
import { parse, stringify, capitalize } from '../../util/urlHelpers';
import { userDisplayNameAsString } from '../../util/data';
import {
  ModalInMobile,
  Button,
  AvatarLarge,
  IconHeartFilled,
  IconHeart,
  ReviewRating,
} from '../../components';

import BookingDatesForm from './BookingDatesForm/BookingDatesForm';
import ProductOrderForm from './ProductOrderForm/ProductOrderForm';
import css from './OrderPanel.module.css';
import SectionAuthorMaybe from '../../containers/ListingPage/SectionAuthorMaybe';
import SectionMapMaybe from '../../containers/ListingPage/SectionMapMaybe';
import paypalIcon from '../../assets/paypal.png';
import NamedLink from '../NamedLink/NamedLink';
import SectionDescriptionMaybe from '../../containers/ListingPage/SectionDescriptionMaybe';

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openOrderModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), orderOpen: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeOrderModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'orderOpen');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const OrderPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    isOwnListing,
    unitType,
    onSubmit,
    title,
    author,
    currentUser,
    onManageDisableScrolling,
    timeSlots,
    fetchTimeSlotsError,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    onContactUser,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    handleWishlist,
    bookmarks,
    geolocation,
    isMobileLayout,
    toggleEnquiryModal,
    onSubmitModal,
    reviewCount,
    reviews,
    fetchReviewsError,
    description,
    richTitle,
    isListingPage
  } = props;

  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;
  const isUnits = unitType === LINE_ITEM_UNITS;
  const shouldHaveBooking = isNightly || isDaily;

  const price = listing.attributes.price;
  const hasListingState = !!listing.attributes.state;
  const isClosed =
    hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  const showBookingDatesForm =
    shouldHaveBooking && hasListingState && !isClosed;
  const showClosedListingHelpText = listing.id && isClosed;
  const { formattedPrice, priceTitle } = priceData(price, intl);
  const isOrderOpen = !!parse(location.search).orderOpen;

  // The listing resource has a relationship: `currentStock`,
  // which you should include when making API calls.
  const currentStock = listing.currentStock?.attributes?.quantity;
  const isOutOfStock =
    config.listingManagementType === 'stock' && currentStock === 0;

  // Show form only when stock is fully loaded. This avoids "Out of stock" UI by
  // default before all data has been downloaded.
  const showProductOrderForm =
    config.listingManagementType === 'stock' &&
    typeof currentStock === 'number';

  const publicData = listing?.attributes?.publicData;

  const { pickupEnabled, shippingEnabled, noShipping = [] } = publicData || {};

  const subTitleText = showClosedListingHelpText
    ? intl.formatMessage({ id: 'OrderPanel.subTitleClosedListing' })
    : null;

  const unitTranslationKey = isNightly
    ? 'OrderPanel.perNight'
    : isDaily
    ? 'OrderPanel.perDay'
    : 'OrderPanel.perUnit';

  const authorDisplayName = userDisplayNameAsString(author, '');

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.orderTitle);
  const id = listing && listing.id && listing.id.uuid;
  
  const bookingForm = showBookingDatesForm ? (
    <BookingDatesForm
      className={css.bookingForm}
      formId="OrderPanelBookingDatesForm"
      submitButtonWrapperClassName={css.bookingDatesSubmitButtonWrapper}
      unitType={unitType}
      onSubmit={onSubmit}
      price={price}
      listingId={listing.id}
      isOwnListing={isOwnListing}
      timeSlots={timeSlots}
      fetchTimeSlotsError={fetchTimeSlotsError}
      onFetchTransactionLineItems={onFetchTransactionLineItems}
      lineItems={lineItems}
      fetchLineItemsInProgress={fetchLineItemsInProgress}
      fetchLineItemsError={fetchLineItemsError}
    />
  ) : showProductOrderForm ? (
    <ProductOrderForm
      formId="OrderPanelProductOrderForm"
      onSubmit={onSubmit}
      price={price}
      currentStock={currentStock}
      pickupEnabled={pickupEnabled}
      shippingEnabled={shippingEnabled}
      noShipping={noShipping && noShipping.length}
      listingId={listing.id}
      isOwnListing={isOwnListing}
      onFetchTransactionLineItems={onFetchTransactionLineItems}
      onContactUser={onContactUser}
      lineItems={lineItems}
      fetchLineItemsInProgress={fetchLineItemsInProgress}
      fetchLineItemsError={fetchLineItemsError}
      toggleEnquiryModal={toggleEnquiryModal}
      onSubmitModal={onSubmitModal}
      publicData={publicData}
      isListingPage={isListingPage}
    />
  ) : null;
console.log(publicData)
  return (
    <div className={classes}>
      <div className={css.orderHeading}>
        <div className={css.title}>
          <h2 className={titleClasses}>{title}</h2>
          {subTitleText ? (
            <div className={css.orderHelp}>{subTitleText}</div>
          ) : null}
        </div>
        {bookmarks && bookmarks.findIndex(e => e == id) > -1 ? (
          <span
            className={css.bookmark}
            onClick={e => {
              handleWishlist(id, e);
            }}
          >
            <IconHeartFilled />
          </span>
        ) : (
          <span
            className={css.bookmark}
            onClick={e => {
              handleWishlist(id, e);
            }}
          >
            <IconHeart />
          </span>
        )}
      </div>
      {/* {!isMobileLayout && (
        <span className={css.reviews}>
          <ReviewRating
            rating={reviewCount ? reviewCount : 0}
            className={css.desktopReviewRating}
            reviewStarClassName={css.reviewRatingStar}
          />
        </span>
      )} */}
      <div className={css.productDetails}>
        {publicData && publicData.make ? <div className={css.productinfo}>
          <strong>Make</strong> : {publicData && publicData.make}
        </div> : null}
        {publicData && publicData.model ? <div className={css.productinfo}>
          <strong>Model</strong> : {publicData && publicData.model}
        </div> : null}
        {publicData && publicData.year ? <div className={css.productinfo}>
          <strong>Year</strong> : {publicData && publicData.year}
        </div> : null}
        <div className={css.productinfo}>
          <strong>Brand</strong> :{' '}
          {(publicData && publicData.subcategory) ||
            (publicData && publicData.brand)}
        </div>
        <div className={css.productinfo}>
          <strong>Condition</strong> :{' '}
          {publicData &&
            publicData.condition &&
            capitalize(publicData.condition)}
        </div>
        {publicData && publicData.partNumber ? <div className={css.productinfo}>
          <strong>Part Number</strong> : {publicData?.partNumber}
        </div> : null}
        {publicData && publicData.wheelDiameter ? <div className={css.productinfo}>
          <strong>Wheel Diameter</strong> : {publicData?.wheelDiameter}
        </div> : null}
        {publicData && publicData.width ? <div className={css.productinfo}>
          <strong>Width (in.)</strong> : {publicData?.width}
        </div> : null}
        {publicData && publicData.wheelBolt ? <div className={css.productinfo}>
          <strong>Bolt Pattern</strong> : {publicData?.wheelBolt}
        </div> : null}
        {publicData && publicData.offSet ? <div className={css.productinfo}>
          <strong>Offset (mm.)</strong> : {publicData?.offSet}
        </div> : null}
      </div>
      <p className={css.price}>{formatMoney(intl, price)}</p>

      {!isOwnListing && bookingForm}
      {isMobileLayout &&
        <SectionDescriptionMaybe
          description={description}
          listingTitle={richTitle}
        />}
      <div className={css.orderSellerInfo}>
        <h2>About the seller</h2>
        <UserCard
          user={listing.author}
          currentUser={currentUser}
          onContactUser={onContactUser}
          isOrderPanelUser={true}
          reviewCount={reviewCount}
        />
      </div>

      <SectionMapMaybe
        geolocation={geolocation}
        publicData={publicData}
        listingId={listing?.id}
      />
      <div className={css.tagsSection}>
        <FormattedMessage id="OrderPanel.tags" />
        <div className={css.tags}>
          {publicData && publicData.tags && publicData.tags.length > 0 ? (
            <>
              {publicData.tags.map(item => (
                <span>{item}</span>
              ))}
            </>
          ) : null}
        </div>
      </div>
      {/* <div className={css.paypalPurchase}>
        <h2>
          <img src={paypalIcon} alt="PayPal" />
          Paypal purchase protection
        </h2>
        <p>
          Every transaction made through a PayPal account is covered by PayPal
          Purchase Protection.
        </p>
      </div> */}
    </div>
  );
};

OrderPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  unitType: config.lineItemUnitType,
  timeSlots: null,
  fetchTimeSlotsError: null,
  lineItems: null,
  fetchLineItemsError: null,
};

OrderPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.lineItemUnitType,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  subTitle: oneOfType([node, string]),
  onManageDisableScrolling: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  onFetchTransactionLineItems: func.isRequired,
  onContactUser: func,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};
export default compose(
  withRouter,
  injectIntl
)(OrderPanel);
