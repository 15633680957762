import React, { useState, useEffect } from 'react';
import { any, bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { propTypes } from '../../../util/types';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
} from '../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';

import css from './TopbarDesktop.module.css';

const TopbarDesktop = props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    isSeller,
    hasSingleNotification
  } = props;

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
    />
  );

  const notificationDot =
  hasSingleNotification ? <span className={css.tabDot}></span> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.navLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <FormattedMessage id="TopbarDesktop.inbox" />
      {notificationDot}
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' &&
      ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage
      ? css.currentPage
      : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel
        className={css.profileMenuLabel}
        isOpenClassName={css.profileMenuIsOpen}
      >
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(
              css.yourListingsLink,
              currentPageClass('ManageListingsPage')
            )}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="FavouritesPage">
          <NamedLink
            className={classNames(
              css.yourListingsLink,
              currentPageClass('FavouritesPage')
            )}
            name="FavouritesPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.FavouritesPage" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(
              css.profileSettingsLink,
              currentPageClass('ProfileSettingsPage')
            )}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(
              css.yourListingsLink,
              currentPageClass('AccountSettingsPage')
            )}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink
      name="SignupPage"
      className={classNames(css.navLink, css.signupLink)}
    >
      <FormattedMessage id="TopbarDesktop.signup" />
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink
      name="LoginPage"
      className={classNames(css.navLink, css.loginLink)}
    >
      <FormattedMessage id="TopbarDesktop.login" />
    </NamedLink>
  );
  const shop = (
    <NamedLink className={css.navLink} name="SearchPage">
      <FormattedMessage id="TopbarDesktop.shop" />
    </NamedLink>
  );

  const sell = (
    <NamedLink className={css.navLink} name={isSeller ? "NewListingPage" : "PayPalMerchantPage"}>
      <FormattedMessage id="TopbarDesktop.createListing" />
    </NamedLink>
  );

  const manageListingsPage = (
    <NamedLink className={css.navLink} name="ManageListingsPage">
      <FormattedMessage id="TopbarDesktop.ManageListingsPage" />
    </NamedLink>
  );
  const currentPath =
  typeof window !== 'undefined' &&
  window.location &&
  window.location.pathname;

  return (
    <nav className={classes}>
      <div className={css.fixWidthContainer}>
        <NamedLink className={css.logoLink} name="LandingPage">
          <Logo
            format="desktop"
            className={css.logo}
            alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
          />
        </NamedLink>
        {currentPath != '/' ? search : null}
        <div className={css.links}>
          {shop}
          {sell}
          {isAuthenticatedOrJustHydrated && currentUserHasListings && manageListingsPage}
          {inboxLink}
          {profileMenu}
          {loginLink}
          {signupLink}
        </div>
      </div>
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
