import React, { useState } from 'react';
import { Field } from 'react-final-form';
import Select, { components } from 'react-select';
// import makeAnimated from 'react-select/animated';
import css from './FieldMultiSelect.module.css';

// const animatedComponents = makeAnimated({ Option, MultiValue });
// style
const customStyles = {
  control: styles => ({
    ...styles,
    height: 'auto',
    paddingLeft: '14px',
    paddingRight: '0px',
    minHeight: '40px',
    boxShadow: 'none',
    margin: '0px',
    fontFamily: '"Open Sans", sans-serif',
    fontWeight: '300',
  }),
  option: (styles, { isFocused, isDisabled, isSelected }) => ({
    ...styles,
    width: '100%',
    backgroundColor: isDisabled
      ? null
      : isFocused
      ? '#ebebeb'
      : isSelected
      ? '#dedede'
      : null,
    color: '#000',
    borderBottom: '1px solid #dedede',
    position: 'relative',
    fontSize: '15px',
  }),
  placeholder: (styles, { isFocused, isDisabled, isSelected }) => ({
    ...styles,
    color: isFocused ? '#B2B2B2' : isSelected ? '#484848' : null,
    padding: '0px',
    margin: '0px',
    fontSize: '15px',
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    paddingRight: '0px',
    transition: 'all .2s ease',
    transform: state.isFocused.menuIsOpen ? 'rotate(180deg)' : null,
  }),
  indicatorSeparator: styles => ({ display: 'none' }),
  option: (styles, { isFocused, isDisabled, isSelected }) => ({
    ...styles,
    color: '#484848',
    backgroundColor: isDisabled
      ? null
      : isFocused
      ? '#F0EDF6'
      : isSelected
      ? '#FFF'
      : null,
    fontFamily: '"Open Sans", sans-serif',
    fontWeight: '400',
    paddingTop: 0,
    paddingBottom: 0,
    margin: 0,
    fontSize: '15px',
  }),
  singleValue: (styles, { isFocused, isDisabled, isSelected }) => ({
    ...styles,
    color: isSelected ? '#767676' : '#484848',
    fontFamily: '"Open Sans", sans-serif',
    fontWeight: '400',
    fontSize: '15px',
  }),
  menu: base => ({
    ...base,
    borderRadius: 0,
    position: 'absolute',
    top: '100%',
    margin: 0,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  menuList: base => ({
    ...base,
    padding: 0,
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: '15px',
  }),
  dropdownIndicator: base => ({
    ...base,
    color: '#484848',
  }),
};
// style
const FieldMultiSelect = props => {
  const [selectedOptions, setSelectedOptions] = useState();
  // REACT-SELECT DOESNT PLAY WELL WITH SSR
  // IF NO WINDOW DONT RENDER
  if (typeof window === 'undefined') {
    return null;
  }
  // CONDITIONALLY IMPORT SELECT MODULES
  // const { default: Select, components } = require('react-select'); // eslint-disable-line global-require
  const {
    name,
    options,
    label,
    className,
    closeMenuOnSelect,
    customOnChange,
    isMulti,
    handleChange,
    searchFld,
    isAgencyPro,
    placeholder= "Select..."
  } = props;
  const status = props.selectedOptions ? css.success : css.attention;
  // const maxSelected = 3;
  const Option = props => (
    <div className={props.searchFld}>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{' '}
        <label className={css.multiselectValueLabel}>{props.label}</label>
      </components.Option>
    </div>
  );

  const MultiValue = props => (
    <components.MultiValue {...props}>
      <span className={css.multiSelectLabel}>{props.data.label}</span>
    </components.MultiValue>
  );
  const renderSelect = typeof window !== 'undefined';
  const disabledLength = isAgencyPro ? 12 : 6;

  return options && renderSelect ? (
    <Field name={name}>
      {props => {
        const { input } = props;
        // PULLING INPUT ONCHANGE OUT OF OBJECT SO THAT WE CAN IMPLEMENT OUT OWN
        const { onChange, ...rest } = input;
        return (
          <div className={className}>
            {label ? <label>{label}</label> : null}
            <div className={status}>
              <Select
                className={css.selectContainer}
                classNamePrefix="react-select"
                closeMenuOnSelect={closeMenuOnSelect}
                hideSelectedOptions={false}
                placeholder={placeholder}
                isMulti={isMulti}
                components={[Option, MultiValue]}
                options={options}
                selected={selectedOptions}
                getOptionValue={value => value.value}
                isOptionDisabled={() =>
                  selectedOptions?.length >= disabledLength
                }
                {...rest}
                // menuIsOpen={true}
                styles={customStyles}
                components={{
                  IndicatorSeparator: () => null,
                }}
                onChange={item => {
                  setSelectedOptions(item);
                  input.onChange(item && item);
                  handleChange(item);
                }}
              />
            </div>
          </div>
        );
      }}
    </Field>
  ) : null;
};

export default FieldMultiSelect;
