import pick from 'lodash/pick';
import config from '../../config';
import {
  deleteLinking,
  initiatePrivileged,
  initiatePrivilegedWithPaypal,
  onUpdateLastSeen,
  sendSms,
  transitionPrivileged,
  transitionPrivilegedWithPaypal,
} from '../../util/api';
import { denormalisedEntities, denormalisedResponseEntities, updatedEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import {
  TRANSITION_REQUEST_PAYMENT,
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY_WITH_PAYPAL,
  TRANSITION_REQUEST_PAYMENT_WITH_PAYPAL,
  isPrivileged,
  TRANSITION_CONFIRM_PAYMENT_WITH_PAYPAL,
} from '../../util/transaction';
import * as log from '../../util/log';
import { uuid } from 'uuidv4';
import { fetchCurrentUserHasOrdersSuccess, fetchCurrentUser } from '../../ducks/user.duck';
import { createPaymentIntent } from '../../util/api';
import moment from 'moment';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/CheckoutPage/SET_INITIAL_VALUES';

export const INITIATE_ORDER_REQUEST = 'app/CheckoutPage/INITIATE_ORDER_REQUEST';
export const INITIATE_ORDER_SUCCESS = 'app/CheckoutPage/INITIATE_ORDER_SUCCESS';
export const INITIATE_ORDER_ERROR = 'app/CheckoutPage/INITIATE_ORDER_ERROR';

export const CONFIRM_PAYMENT_REQUEST = 'app/CheckoutPage/CONFIRM_PAYMENT_REQUEST';
export const CONFIRM_PAYMENT_SUCCESS = 'app/CheckoutPage/CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_ERROR = 'app/CheckoutPage/CONFIRM_PAYMENT_ERROR';

export const SPECULATE_TRANSACTION_REQUEST = 'app/ListingPage/SPECULATE_TRANSACTION_REQUEST';
export const SPECULATE_TRANSACTION_SUCCESS = 'app/ListingPage/SPECULATE_TRANSACTION_SUCCESS';
export const SPECULATE_TRANSACTION_ERROR = 'app/ListingPage/SPECULATE_TRANSACTION_ERROR';

export const STRIPE_CUSTOMER_REQUEST = 'app/CheckoutPage/STRIPE_CUSTOMER_REQUEST';
export const STRIPE_CUSTOMER_SUCCESS = 'app/CheckoutPage/STRIPE_CUSTOMER_SUCCESS';
export const STRIPE_CUSTOMER_ERROR = 'app/CheckoutPage/STRIPE_CUSTOMER_ERROR';

// ================ Reducer ================ //

const listingRelationship = txResponse => {
  return txResponse.data.data.relationships.listing.data;
};

const initialState = {
  listing: null,
  orderData: null,
  speculateTransactionInProgress: false,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  initiateOrderError: null,
  confirmPaymentError: null,
  stripeCustomerFetched: false,
  orderInProgress: false 
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SPECULATE_TRANSACTION_REQUEST:
      return {
        ...state,
        speculateTransactionInProgress: true,
        speculateTransactionError: null,
        speculatedTransaction: null,
      };
    case SPECULATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculatedTransaction: payload.transaction,
      };
    case SPECULATE_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculateTransactionError: payload,
      };

    case INITIATE_ORDER_REQUEST:
      return { ...state, initiateOrderError: null, orderInProgress: true };
    case INITIATE_ORDER_SUCCESS:
      return { ...state, transaction: payload, orderInProgress: false  };
    case INITIATE_ORDER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, initiateOrderError: payload };

    case CONFIRM_PAYMENT_REQUEST:
      return { ...state, confirmPaymentError: null };
    case CONFIRM_PAYMENT_SUCCESS:
      return state;
    case CONFIRM_PAYMENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, confirmPaymentError: payload };

    case STRIPE_CUSTOMER_REQUEST:
      return { ...state, stripeCustomerFetched: false };
    case STRIPE_CUSTOMER_SUCCESS:
      return { ...state, stripeCustomerFetched: true };
    case STRIPE_CUSTOMER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, stripeCustomerFetchError: payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const initiateOrderRequest = () => ({ type: INITIATE_ORDER_REQUEST });

const initiateOrderSuccess = order => ({
  type: INITIATE_ORDER_SUCCESS,
  payload: order,
});

const initiateOrderError = e => ({
  type: INITIATE_ORDER_ERROR,
  error: true,
  payload: e,
});

const confirmPaymentRequest = () => ({ type: CONFIRM_PAYMENT_REQUEST });

const confirmPaymentSuccess = orderId => ({
  type: CONFIRM_PAYMENT_SUCCESS,
  payload: orderId,
});

const confirmPaymentError = e => ({
  type: CONFIRM_PAYMENT_ERROR,
  error: true,
  payload: e,
});

export const speculateTransactionRequest = () => ({ type: SPECULATE_TRANSACTION_REQUEST });

export const speculateTransactionSuccess = transaction => ({
  type: SPECULATE_TRANSACTION_SUCCESS,
  payload: { transaction },
});

export const speculateTransactionError = e => ({
  type: SPECULATE_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

export const stripeCustomerRequest = () => ({ type: STRIPE_CUSTOMER_REQUEST });
export const stripeCustomerSuccess = () => ({ type: STRIPE_CUSTOMER_SUCCESS });
export const stripeCustomerError = e => ({
  type: STRIPE_CUSTOMER_ERROR,
  error: true,
  payload: e,
});

/* ================ Thunks ================ */

export const initiateOrder = (orderParams, transactionId) => (dispatch, getState, sdk) => {
  dispatch(initiateOrderRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;
  const transition = isTransition
    ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY
    : TRANSITION_REQUEST_PAYMENT;
  const isPrivilegedTransition = isPrivileged(transition);

  const { deliveryMethod, quantity, bookingDates, ...otherOrderParams } = orderParams;
  const quantityMaybe = quantity ? { stockReservationQuantity: quantity } : {};
  const bookingParamsMaybe = bookingDates || {};

  // Parameters only for client app's server
  const orderData = {
    deliveryMethod,
  };

  // Parameters for Flex API
  const transitionParams = {
    ...quantityMaybe,
    ...bookingParamsMaybe,
    ...otherOrderParams,
  };

  const bodyParams = isTransition
    ? {
      id: transactionId,
      transition,
      params: transitionParams,
    }
    : {
      processAlias: config.transactionProcessAlias,
      transition,
      params: transitionParams,
    };
  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSucces = response => {
    const entities = denormalisedResponseEntities(response);
    const order = entities[0];
    dispatch(initiateOrderSuccess(order));
    dispatch(fetchCurrentUserHasOrdersSuccess(true));
    return order;
  };

  const handleError = e => {
    dispatch(initiateOrderError(storableError(e)));
    const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
    log.error(e, 'initiate-order-failed', {
      ...transactionIdMaybe,
      listingId: orderParams.listingId.uuid,
      ...quantityMaybe,
      ...bookingParamsMaybe,
      ...orderData,
    });
    throw e;
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: false, orderData, bodyParams, queryParams })
      .then(handleSucces)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transition(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: false, orderData, bodyParams, queryParams })
      .then(handleSucces)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiate(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  }
};

export const confirmPayment = orderParams => (dispatch, getState, sdk) => {
  dispatch(confirmPaymentRequest());

  const bodyParams = {
    id: orderParams.transactionId,
    transition: TRANSITION_CONFIRM_PAYMENT,
    params: {},
  };

  return sdk.transactions
    .transition(bodyParams)
    .then(response => {
      const order = response.data.data;
      dispatch(confirmPaymentSuccess(order.id));
      return order;
    })
    .catch(e => {
      dispatch(confirmPaymentError(storableError(e)));
      const transactionIdMaybe = orderParams.transactionId
        ? { transactionId: orderParams.transactionId.uuid }
        : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
      });
      throw e;
    });
};

export const sendMessage = params => (dispatch, getState, sdk) => {
  const message = params.message;
  const orderId = params.id;

  if (message) {
    return sdk.messages
      .send({ transactionId: orderId, content: message })
      .then(() => {
        // sendSms({ details: message });
        return { orderId, messageSuccess: true };
      })
      .catch(e => {
        log.error(e, 'initial-message-send-failed', { txId: orderId });
        return { orderId, messageSuccess: false };
      });
  } else {
    return Promise.resolve({ orderId, messageSuccess: true });
  }
};

export const sendMessageToProvider = params => (dispatch, getState, sdk) => {
  const { phoneNumber, details } = params;
  if (phoneNumber) {
    sendSms({ details, phoneNumber });
  }
};

/**
 * Initiate or transition the speculative transaction with the given
 * booking details
 *
 * The API allows us to do speculative transaction initiation and
 * transitions. This way we can create a test transaction and get the
 * actual pricing information as if the transaction had been started,
 * without affecting the actual data.
 *
 * We store this speculative transaction in the page store and use the
 * pricing info for the booking breakdown to get a proper estimate for
 * the price with the chosen information.
 */
export const speculateTransaction = (orderParams, transactionId) => (dispatch, getState, sdk) => {
  dispatch(speculateTransactionRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;
  const transition = isTransition
    ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY
    : TRANSITION_REQUEST_PAYMENT;
  const isPrivilegedTransition = isPrivileged(transition);

  const { deliveryMethod, quantity, bookingDates, ...otherOrderParams } = orderParams;
  const quantityMaybe = quantity ? { stockReservationQuantity: quantity } : {};
  const bookingParamsMaybe = bookingDates || {};

  // Parameters only for client app's server
  const orderData = {
    deliveryMethod,
  };

  // Parameters for Flex API
  const transitionParams = {
    ...quantityMaybe,
    ...bookingParamsMaybe,
    ...otherOrderParams,
    cardToken: 'CheckoutPage_speculative_card_token',
  };

  const bodyParams = isTransition
    ? {
      id: transactionId,
      transition,
      params: transitionParams,
    }
    : {
      processAlias: config.transactionProcessAlias,
      transition,
      params: transitionParams,
    };

  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSuccess = response => {
    const entities = denormalisedResponseEntities(response);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the speculate response');
    }
    const tx = entities[0];
    dispatch(speculateTransactionSuccess(tx));
  };

  const handleError = e => {
    log.error(e, 'speculate-transaction-failed', {
      listingId: transitionParams.listingId.uuid,
      ...quantityMaybe,
      ...bookingParamsMaybe,
      ...orderData,
    });
    return dispatch(speculateTransactionError(storableError(e)));
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: true, orderData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transitionSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: true, orderData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiateSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  }
};

// StripeCustomer is a relantionship to currentUser
// We need to fetch currentUser with correct params to include relationship
export const stripeCustomer = () => (dispatch, getState, sdk) => {
  dispatch(stripeCustomerRequest());

  return dispatch(fetchCurrentUser({ include: ['stripeCustomer.defaultPaymentMethod'] }))
    .then(response => {
      dispatch(stripeCustomerSuccess());
    })
    .catch(e => {
      dispatch(stripeCustomerError(storableError(e)));
    });
};

export const sendText = () => (dispatch, getState, sdk) => {
  // sendSms()
};

export const initiateOrderWithPayPal = (orderParams, transactionId, offerPrice) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(initiateOrderRequest());
  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;
  const transition = isTransition
    ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY_WITH_PAYPAL
    : TRANSITION_REQUEST_PAYMENT_WITH_PAYPAL;
  const isPrivilegedTransition = isPrivileged(transition);

  const { deliveryMethod, quantity, bookingDates, merchantId, protectedDataMaybe, ...otherOrderParams } = orderParams;
  const quantityMaybe = quantity ? { stockReservationQuantity: quantity } : {};
  const bookingParamsMaybe = bookingDates || {};

  // Parameters only for client app's server
  const orderData = {
    deliveryMethod,
    protectedDataMaybe
  };

  // Parameters for Flex API
  const transitionParams = {
    ...quantityMaybe,
    ...bookingParamsMaybe,
    ...otherOrderParams,
  };

  const bodyParams = isTransition
    ? {
      id: transactionId,
      transition,
      params: transitionParams,
    }
    : {
      processAlias: config.transactionProcessAlias,
      transition,
      params: transitionParams,
    };
  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSucces = response => {
    const entities = denormalisedResponseEntities(response);
    const order = entities[0];
    dispatch(initiateOrderSuccess(order));
    dispatch(fetchCurrentUserHasOrdersSuccess(true));
    return order;
  };

  const handleError = e => {
    dispatch(initiateOrderError(storableError(e)));
    const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
    log.error(e, 'initiate-order-failed', {
      ...transactionIdMaybe,
      listingId: orderParams.listingId.uuid,
      ...quantityMaybe,
      ...bookingParamsMaybe,
      ...orderData,
    });
    throw e;
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivilegedWithPaypal({
      merchantId,
      isSpeculative: false,
      orderData,
      bodyParams,
      queryParams,
      offerPrice
    })
      .then(handleSucces)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transition(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivilegedWithPaypal({
      merchantId,
      isSpeculative: false,
      orderData,
      bodyParams,
      queryParams,
      offerPrice
    })
      .then(handleSucces)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiate(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  }
};

export const speculateTransactionWithPayPal = (orderParams, transactionId, offerPrice) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(speculateTransactionRequest());
  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;
  const transition = isTransition
    ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY_WITH_PAYPAL
    : TRANSITION_REQUEST_PAYMENT_WITH_PAYPAL;
  const isPrivilegedTransition = isPrivileged(transition);

  const { deliveryMethod, quantity, bookingDates, ...otherOrderParams } = orderParams;
  const quantityMaybe = quantity ? { stockReservationQuantity: quantity } : {};
  const bookingParamsMaybe = bookingDates || {};

  // Parameters only for client app's server
  const orderData = {
    deliveryMethod,
  };

  // Parameters for Flex API
  const transitionParams = {
    ...quantityMaybe,
    ...bookingParamsMaybe,
    ...otherOrderParams,
    cardToken: 'CheckoutPage_speculative_card_token',
  };

  const bodyParams = isTransition
    ? {
      id: transactionId,
      transition,
      params: transitionParams,
    }
    : {
      processAlias: config.transactionProcessAlias,
      transition,
      params: transitionParams,
    };

  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSuccess = response => {
    const entities = denormalisedResponseEntities(response);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the speculate response');
    }
    const tx = entities[0];
    dispatch(speculateTransactionSuccess(tx));
  };

  const handleError = e => {
    log.error(e, 'speculate-transaction-failed', {
      listingId: transitionParams.listingId.uuid,
      ...quantityMaybe,
      ...bookingParamsMaybe,
      ...orderData,
    });
    return dispatch(speculateTransactionError(storableError(e)));
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: true, orderData, bodyParams, queryParams, offerPrice })
      .then(handleSuccess)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transitionSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: true, orderData, bodyParams, queryParams, offerPrice })
      .then(handleSuccess)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiateSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  }
};

export const confirmPaymentWithPayPal = (orderParams, isPickup) => (dispatch, getState, sdk) => {
  dispatch(confirmPaymentRequest());
  const secretCodeUuid = uuid();
  const secretCode = secretCodeUuid && secretCodeUuid.split('-') && secretCodeUuid.split('-')[0];
  const bodyParams = {
    id: orderParams.transactionId,
    transition: TRANSITION_CONFIRM_PAYMENT_WITH_PAYPAL,
    params: {
      protectedData: {
        paypalCaptureId: orderParams.captureId,
      },
    },
  };
  const pickupBodyParams = {
    id: orderParams.transactionId,
    transition: TRANSITION_CONFIRM_PAYMENT_WITH_PAYPAL,
    params: {
      protectedData: {
        paypalCaptureId: orderParams.captureId,
        secretCode
      },
    },
  };
  const txParams = isPickup ? pickupBodyParams : bodyParams;
  const queryParams = {
    include: ['provider', 'customer', 'listing'],
    expand: true,
  };
  return sdk.transactions
    .transition(txParams, queryParams)
    .then(response => {
      const order = response.data.data;
      const listingId = listingRelationship(response).id;
      const entities = updatedEntities({}, response.data);
      const listingRef = { id: listingId, type: 'listing' };
      const transactionRef = { id: order.id, type: 'transaction' };
      const denormalised = denormalisedEntities(entities, [listingRef, transactionRef]);
      const listing = denormalised[0];
      const transaction = denormalised[1];

      const customerName = transaction?.customer?.attributes?.profile?.displayName;
      const providerName = transaction?.provider?.attributes?.profile?.displayName;

      const customerPhoneNumber = transaction && transaction.customer && transaction.customer.attributes && transaction.customer.attributes.profile && transaction.customer.attributes.profile.publicData && transaction.customer.attributes.profile.publicData.phoneNumber;

      const customerDetails = `
        Congrats! You’ve purchased ${listing?.attributes?.title} from ${providerName}. Please present this pick up code to ${providerName} at pick up to receive your purchase. Code: ${secretCode} . For our community’s safety please meet at the agreed upon location and follow local social distancing regulations.`

      if (customerPhoneNumber && isPickup) {
        sendSms({ details: customerDetails, phoneNumber: customerPhoneNumber });
      };

      const deliveryMethod = transaction && transaction.attributes && transaction.attributes.protectedData && transaction.attributes.protectedData.deliveryMethod;

      const isShipping = deliveryMethod && deliveryMethod == 'shipping';

      const providerPickupDetails = `Congrats! ${customerName} has purchased ${listing?.attributes?.title}. Please get the order ready for pick up and remember to enter the pick up code provided by the buyer. Your funds will be released immediately once the pick up code is entered. For our community’s safety please meet at the agreed upon location and follow local social distancing regulations.`

      const providerShippingDetails = `Congrats! ${customerName} has purchased ${listing?.attributes?.title}. 'Please get the order ready for shipment and remember to enter a tracking number. Your funds will be released immediately once tracking is provided. If tracking is not provided within 14 days, the order will expire automatically.`

      const providerPhoneNumber = transaction && transaction.provider && transaction.provider.attributes && transaction.provider.attributes.profile && transaction.provider.attributes.profile.publicData && transaction.provider.attributes.profile.publicData.phoneNumber;

      if (providerPhoneNumber && isPickup) {
        sendSms({ details: providerPickupDetails, phoneNumber: providerPhoneNumber });
      } else if (providerPhoneNumber && isShipping) {
        sendSms({ details: providerShippingDetails, phoneNumber: providerPhoneNumber });
      }

      dispatch(confirmPaymentSuccess(order.id));
      return order;
    })
    .catch(e => {
      dispatch(confirmPaymentError(storableError(e)));
      const transactionIdMaybe = orderParams.transactionId
        ? { transactionId: orderParams.transactionId.uuid }
        : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
      });
      throw e;
    });
};

export const onCreatePaymentIntent = (params) => async (dispatch, getState, sdk) => {
  dispatch(initiateOrderRequest());
  const { totalPrice, paymentMethod, customerId, transferTo, orderParams, savedCard, isSaveChecked, offerPrice, providerCommission, transaction } = params;

  const paymentIntent = totalPrice && paymentMethod && customerId && transferTo && await
  createPaymentIntent({ totalPrice, paymentMethod: paymentMethod ? paymentMethod : savedCard, customerId, transferTo, isSaveChecked, providerCommission });

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = transaction;
  const transactionId = transaction && transaction.id;

  const transition = isTransition
    ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY_WITH_PAYPAL
    : TRANSITION_REQUEST_PAYMENT_WITH_PAYPAL;
  const isPrivilegedTransition = isPrivileged(transition);

  const { deliveryMethod, quantity, bookingDates, protectedDataMaybe, ...otherOrderParams } = orderParams;
  const quantityMaybe = quantity ? { stockReservationQuantity: quantity } : {};
  const bookingParamsMaybe = bookingDates || {};

  // Parameters only for client app's server
  const orderData = {
    deliveryMethod,
    protectedDataMaybe
  };

  // Parameters for Flex API
  const transitionParams = {
    ...quantityMaybe,
    ...bookingParamsMaybe,
    ...otherOrderParams,
  };

  const bodyParams = isTransition
    ? {
      id: transactionId,
      transition,
      params: transitionParams,
    }
    : {
      processAlias: config.customBookingProcess,
      transition,
      params: transitionParams,
    };
  const queryParams = {
    include: ['booking', 'customer', 'provider', 'listing '],
    expand: true,
  };

  const handleSucces = async response => {
    const entities = denormalisedResponseEntities(response);
    const order = entities[0];
    const transaction = order;
    const listing = transaction && transaction.listing;
    const isPickup = deliveryMethod && deliveryMethod == 'pickup';

    const secretCodeUuid = uuid();
    const secretCode = secretCodeUuid && secretCodeUuid.split('-') && secretCodeUuid.split('-')[0];
    
    const protectedData = deliveryMethod == 'pickup' ? { paymentIntent: JSON.stringify(paymentIntent), secretCode, lastSeenUpdated:moment().format('YYYY-MM-DD HH:mm:ss') } : { paymentIntent: JSON.stringify(paymentIntent), lastSeenUpdated:moment().format('YYYY-MM-DD HH:mm:ss') };
    
    const orderUpdated = paymentIntent && await sdk.transactions
    .transition({
      id: order.id, transition: TRANSITION_CONFIRM_PAYMENT_WITH_PAYPAL,
      params: {
        protectedData,
      }
    }, { expand: true });
    const entitiesUpdated = denormalisedResponseEntities(orderUpdated);
    const orderNew = entitiesUpdated[0];
    dispatch(initiateOrderSuccess(orderNew));
    dispatch(fetchCurrentUserHasOrdersSuccess(true));


    const customerId = transaction?.customer?.id?.uuid;
    const txData = listing && listing.attributes && listing.attributes.publicData && listing.attributes.publicData.txData;
    const filteredTxData = txData && txData.filter(item => item.authorId !== customerId);

    if (listing && listing.id && filteredTxData) {
      deleteLinking({ txData: order.id, listingId: listing?.id?.uuid })
    }
    const customerName = transaction?.customer?.attributes?.profile?.displayName;
    const providerName = transaction?.provider?.attributes?.profile?.displayName;

    const customerPhoneNumber = transaction && transaction.customer && transaction.customer.attributes && transaction.customer.attributes.profile && transaction.customer.attributes.profile.publicData && transaction.customer.attributes.profile.publicData.phoneNumber;

    const customerDetails = `
      Congrats! You’ve purchased ${listing?.attributes?.title} from ${providerName}. Please present this pick up code to ${providerName} at pick up to receive your purchase. Code: ${secretCode} . For our community’s safety please meet at the agreed upon location`

   
    const customerShippingDetails = `Congrats! You’ve purchased ${listing?.attributes?.title} from ${providerName}. Be on the lookout for tracking details soon to be provided by ${providerName} and remember to drop a seller rating once you receive your purchase.`

   

    const isShipping = deliveryMethod && deliveryMethod == 'shipping' || deliveryMethod == 'noShipping';

    const providerPickupDetails = `Congrats! ${customerName} has purchased ${listing?.attributes?.title}.  Please get the order ready for pick up and remember to enter the pick up code provided by the buyer. Your funds will show on your Stripe Dashboard once the confirmation code is entered. For our community’s safety please meet at the agreed upon location`

    const providerShippingDetails = `Congrats! ${customerName} has purchased ${listing?.attributes?.title}. 'Please get the order ready for shipment and remember to enter a tracking number. Your funds will show on your Stripe Dashboard once tracking is provided. If tracking is not provided within 14 days, the order will expire automatically`

    const providerPhoneNumber = transaction && transaction.provider && transaction.provider.attributes && transaction.provider.attributes.profile && transaction.provider.attributes.profile.publicData && transaction.provider.attributes.profile.publicData.phoneNumber;

    const seenParams = { txId: order.id.uuid, providerSeen: false, customerSeen: false };

    onUpdateLastSeen(seenParams);

    if (customerPhoneNumber && isPickup) {
      sendSms({ details: customerDetails, phoneNumber: customerPhoneNumber });
    } else if (customerPhoneNumber && isShipping) {
      sendSms({ details: customerShippingDetails, phoneNumber: customerPhoneNumber });
    }
    
    if (providerPhoneNumber && isPickup) {
      sendSms({ details: providerPickupDetails, phoneNumber: providerPhoneNumber });
    } else if (providerPhoneNumber && isShipping) {
      sendSms({ details: providerShippingDetails, phoneNumber: providerPhoneNumber });
    }
    return orderNew
  }

  const handleError = e => {
    dispatch(initiateOrderError(storableError(e)));
    const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
    log.error(e, 'initiate-order-failed', {
      ...transactionIdMaybe,
      listingId: orderParams.listingId.uuid,
      ...quantityMaybe,
      ...bookingParamsMaybe,
      ...orderData,
    });
    throw e;
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: false, orderData, bodyParams, queryParams, offerPrice })
      .then(handleSucces)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transition(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: false, orderData, bodyParams, queryParams, offerPrice })
      .then(handleSucces)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiate(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  }
};
