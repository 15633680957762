import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import css from './IconHelp.module.css';

const IconHelp = props => {
  const { className, rootClassName, isFilled } = props;
  const filledOrDefault = isFilled ? css.filled : css.root;
  const classes = classNames(rootClassName || filledOrDefault, className);

  return (
    <svg
      className={classes}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      style={{fill:'transparent'}}
    >
      <circle cx="7.99976" cy="8" r="7.335" stroke="#484848" stroke-width="1.33" />
      <path
        d="M7.73684 4C6.26316 4 5 5.26316 5 6.73684C5 6.94737 5.21053 7.15789 5.42105 7.15789C5.63158 7.15789 5.84211 6.94737 5.84211 6.73684C5.84211 5.68421 6.68421 4.84211 7.73684 4.84211C8.78947 4.84211 9.63158 5.68421 9.63158 6.73684C9.63158 7.78947 8.68421 8.52632 7.73684 8.52632C7.52632 8.52632 7.31579 8.73684 7.31579 8.94737V10.3158C7.31579 10.5263 7.52632 10.7368 7.73684 10.7368C7.94737 10.7368 8.1579 10.5263 8.1579 10.3158V9.36842C9.42105 9.15789 10.4737 8 10.4737 6.63158C10.4737 5.15789 9.21053 4 7.73684 4Z"
        fill="#484848"
        stroke="#484848"
        stroke-width="0.25"
      />
      <path
        d="M7.73802 12.7373C8.08684 12.7373 8.3696 12.4545 8.3696 12.1057C8.3696 11.7569 8.08684 11.4741 7.73802 11.4741C7.38921 11.4741 7.10645 11.7569 7.10645 12.1057C7.10645 12.4545 7.38921 12.7373 7.73802 12.7373Z"
        fill="#484848"
        stroke="#484848"
        stroke-width="0.25"
      />
    </svg>
  );
};

IconHelp.defaultProps = { className: null, rootClassName: null, isFilled: false };

const { bool, string } = PropTypes;

IconHelp.propTypes = {
  className: string,
  rootClassName: string,
  isFilled: bool,
};

export default IconHelp;
