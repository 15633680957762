import { fetchCurrentUser } from '../../ducks/user.duck';
import { setInitialValues as setInitialValuesForPaymentMethods } from '../../ducks/paymentMethods.duck';
import { storableError } from '../../util/errors';
import * as log from '../../util/log';
import { createPaymentMethod, deleteCard } from '../../util/api';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
// ================ Action types ================ //

export const ATTACHMENT_METHOD_REQUEST = 'app/PaymentMethodsPage/ATTACHMENT_METHOD_REQUEST';
export const ATTACHMENT_METHOD_SUCCESS = 'app/PaymentMethodsPage/ATTACHMENT_METHOD_SUCCESS';

export const DETACHMENT_METHOD_REQUEST = 'app/PaymentMethodsPage/DETACHMENT_METHOD_REQUEST';
export const DETACHMENT_METHOD_SUCCESS = 'app/PaymentMethodsPage/DETACHMENT_METHOD_SUCCESS';

export const SETUP_INTENT_REQUEST = 'app/PaymentMethodsPage/SETUP_INTENT_REQUEST';
export const SETUP_INTENT_SUCCESS = 'app/PaymentMethodsPage/SETUP_INTENT_SUCCESS';
export const SETUP_INTENT_ERROR = 'app/PaymentMethodsPage/SETUP_INTENT_ERROR';

export const STRIPE_CUSTOMER_REQUEST = 'app/PaymentMethodsPage/STRIPE_CUSTOMER_REQUEST';
export const STRIPE_CUSTOMER_SUCCESS = 'app/PaymentMethodsPage/STRIPE_CUSTOMER_SUCCESS';
export const STRIPE_CUSTOMER_ERROR = 'app/PaymentMethodsPage/STRIPE_CUSTOMER_ERROR';

// ================ Reducer ================ //

const initialState = {
  setupIntentInProgress: false,
  setupIntentError: null,
  setupIntent: null,
  stripeCustomerFetched: false,
};

export default function payoutMethodsPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case ATTACHMENT_METHOD_REQUEST:
      return { ...state, attachmentInProgress: true, attached: false, detached:false };
    case ATTACHMENT_METHOD_SUCCESS:
      return {
        ...state,
        attachmentInProgress: false,
        attached: true
      };
    case DETACHMENT_METHOD_REQUEST:
      return { ...state, detachmentInProgress: true, detached: false, attached:false };
    case DETACHMENT_METHOD_SUCCESS:
      return {
        ...state,
        detachmentInProgress: false,
        detached: true
      };
    case SETUP_INTENT_REQUEST:
      return { ...state, setupIntentInProgress: true, setupIntentError: null };
    case SETUP_INTENT_SUCCESS:
      return {
        ...state,
        setupIntentInProgress: false,
        setupIntentError: null,
        setupIntent: payload,
      };
    case SETUP_INTENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, setupIntentInProgress: false, setupIntentError: null };
    case STRIPE_CUSTOMER_REQUEST:
      return { ...state, stripeCustomerFetched: false };
    case STRIPE_CUSTOMER_SUCCESS:
      return { ...state, stripeCustomerFetched: true };
    case STRIPE_CUSTOMER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, stripeCustomerFetchError: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const attachPaymentMethodRequest = () => ({ type: ATTACHMENT_METHOD_REQUEST });
export const attachPaymentMethodSuccess = () => ({ type: ATTACHMENT_METHOD_SUCCESS });

export const detachPaymentMethodRequest = () => ({ type: DETACHMENT_METHOD_REQUEST });
export const detachPaymentMethodSuccess = () => ({ type: DETACHMENT_METHOD_SUCCESS });

export const setupIntentRequest = () => ({ type: SETUP_INTENT_REQUEST });
export const setupIntentSuccess = () => ({ type: SETUP_INTENT_SUCCESS });
export const setupIntentError = e => ({
  type: SETUP_INTENT_ERROR,
  error: true,
  payload: e,
});

export const stripeCustomerRequest = () => ({ type: STRIPE_CUSTOMER_REQUEST });
export const stripeCustomerSuccess = () => ({ type: STRIPE_CUSTOMER_SUCCESS });
export const stripeCustomerError = e => ({
  type: STRIPE_CUSTOMER_ERROR,
  error: true,
  payload: e,
});
// ================ Thunks ================ //

export const createStripeSetupIntent = () => (dispatch, getState, sdk) => {
  dispatch(setupIntentRequest());
  return sdk.stripeSetupIntents
    .create()
    .then(response => {
      const setupIntent = response.data.data;
      dispatch(setupIntentSuccess(setupIntent));
      return setupIntent;
    })
    .catch(e => {
      const error = storableError(e);
      log.error(error, 'create-setup-intent-failed');
      dispatch(setupIntentError(error));
      return { createStripeSetupIntentSuccess: false };
    });
};

export const stripeCustomer = () => (dispatch, getState, sdk) => {
  dispatch(stripeCustomerRequest());

  return dispatch(fetchCurrentUser({ include: ['stripeCustomer.defaultPaymentMethod'] }))
    .then(response => {
      dispatch(stripeCustomerSuccess());
    })
    .catch(e => {
      const error = storableError(e);
      log.error(error, 'fetch-stripe-customer-failed');
      dispatch(stripeCustomerError(error));
    });
};

export const attachPaymentMethod = (params) => async (dispatch, getState, sdk) => {
  dispatch(attachPaymentMethodRequest());
  const { paymentMethod } = params;
  const currentUser = getState().user.currentUser;
  const email = currentUser?.attributes?.email;
  const protectedData = currentUser && currentUser.attributes && currentUser.attributes.profile && currentUser.attributes.profile.protectedData;
  const customerData = protectedData && protectedData.customer && JSON.parse(protectedData.customer);
  const customerId = customerData && customerData.type == 'card' ? customerData.customer : customerData?.id;

  const paymentMethodRes = await createPaymentMethod({ paymentMethod: paymentMethod.id, customerId });

  const customer = paymentMethodRes && paymentMethodRes.customer;

  if (customer) {
    const profile = {
      protectedData: {
        customer: JSON.stringify(customer)
      }
    };
    dispatch(updateProfile(profile));
    dispatch(fetchCurrentUser());
    dispatch(attachPaymentMethodSuccess());
  }
};

export const detachPaymentMethod = () => async (dispatch, getState, sdk) => {
  dispatch(detachPaymentMethodRequest());
  const currentUser = getState().user.currentUser;
  const protectedData = currentUser && currentUser.attributes && currentUser.attributes.profile && currentUser.attributes.profile.protectedData;
  const customerData = protectedData && protectedData.customer && JSON.parse(protectedData.customer);

  const paymentMethodId = customerData && customerData.id;
  const paymentMethodRes = await deleteCard({ paymentMethodId });

  if (paymentMethodRes) {
    const profile = {
      protectedData: {
        card: null
      }
    };

    dispatch(updateProfile(profile));
    dispatch(fetchCurrentUser());
    dispatch(detachPaymentMethodSuccess());
  }
};


export const loadData = () => (dispatch, getState, sdk) => {
  dispatch(setInitialValuesForPaymentMethods());

  return dispatch(stripeCustomer());
};
