import pick from 'lodash/pick';
import { fetchPaypalMerchantSignupStatus } from '../../ducks/paypal.duck';
import { currentUserShowSuccess, fetchCurrentUser } from '../../ducks/user.duck';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/PeyPalMerchantPage/SET_INITIAL_VALUES';
export const SAVE_MERCHANT_REQUEST = 'app/PeyPalMerchantPage/SAVE_MERCHANT_REQUEST';
export const SAVE_MERCHANT_SUCCESS = 'app/PeyPalMerchantPage/SAVE_MERCHANT_SUCCESS';
export const SAVE_MERCHANT_ERROR = 'app/PeyPalMerchantPage/SAVE_MERCHANT_ERROR';

// ================ Reducer ================ //

const initialState = {
  merchantSaveInProgress: false,
  merchantSaved: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SAVE_MERCHANT_REQUEST:
      return { ...state, merchantSaveInProgress: true };
    case SAVE_MERCHANT_ERROR:
      return { ...state, merchantSaveInProgress: false };
    case SAVE_MERCHANT_SUCCESS:
      return { ...state, merchantSaveInProgress: false, merchantSaved: true };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const saveMerchantRequest = () => ({
  type: SAVE_MERCHANT_REQUEST,
});
export const saveMerchantError = error => ({
  type: SAVE_MERCHANT_ERROR,
  payload: error,
  error: true,
});
export const saveMerchantSuccess = () => ({
  type: SAVE_MERCHANT_SUCCESS,
});

// ================ Thunks ================ //

const requestSaveMerchant = merchantId => (dispatch, getState, sdk) => {
  dispatch(saveMerchantRequest());
  return sdk.currentUser
    .updateProfile({ publicData: { merchantId } }, { expand: true })
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }

      const currentUser = entities[0];
      dispatch(currentUserShowSuccess(currentUser));
      dispatch(saveMerchantRequest());
    })
    .catch(e => {
      dispatch(saveMerchantError(storableError(e)));
    });
};

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const queryParams = parse(search);

  const { merchantId: trackingId, merchantIdInPayPal } = queryParams;

  const isOnboardingReturn =
    params?.returnURLType === 'onboardingResult' && trackingId && merchantIdInPayPal;

  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialValues());

  return dispatch(fetchCurrentUser()).then(response => {
    const currentUser = getState().user.currentUser;

    const shouldSaveMerchant =
      isOnboardingReturn && currentUser && currentUser.id.uuid === trackingId;
    if (shouldSaveMerchant) {
      dispatch(requestSaveMerchant(merchantIdInPayPal));
    }
    if (currentUser && currentUser.attributes.profile.publicData?.merchantId) {
      dispatch(fetchPaypalMerchantSignupStatus());
    }
    return response;
  });
};
