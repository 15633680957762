import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { TRANSITIONS, TRANSITION_AUTO_CANCEL, TRANSITION_AUTO_CANCEL_FROM_DISPUTED, TRANSITION_AUTO_CANCEL_FROM_DISPUTED_WITH_PAYPAL, TRANSITION_AUTO_CANCEL_WITH_PAYPAL, TRANSITION_AUTO_COMPLETE, TRANSITION_AUTO_MARK_RECEIVED, TRANSITION_AUTO_MARK_RECEIVED_WITH_PAYPAL, TRANSITION_CANCEL, TRANSITION_CANCEL_FROM_DISPUTED, TRANSITION_CANCEL_FROM_DISPUTED_WITH_PAYPAL, TRANSITION_CANCEL_WITH_PAYPAL, TRANSITION_CONFIRM_PAYMENT, TRANSITION_CONFIRM_PAYMENT_WITH_PAYPAL, TRANSITION_DISPUTE, TRANSITION_DISPUTE_WITH_PAYPAL, TRANSITION_ENQUIRE, TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD, TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD, TRANSITION_EXPIRE_REVIEW_PERIOD, TRANSITION_MARK_DELIVERED, TRANSITION_MARK_DELIVERED_WITH_PAYPAL, TRANSITION_MARK_RECEIVED, TRANSITION_MARK_RECEIVED_FROM_DISPUTED, TRANSITION_MARK_RECEIVED_FROM_DISPUTED_WITH_PAYPAL, TRANSITION_MARK_RECEIVED_FROM_PURCHASED, TRANSITION_MARK_RECEIVED_FROM_PURCHASED_WITH_PAYPAL, TRANSITION_MARK_RECEIVED_WITH_PAYPAL, TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY, TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY_WITH_PAYPAL, TRANSITION_REVIEW_1_BY_CUSTOMER, TRANSITION_REVIEW_1_BY_PROVIDER, TRANSITION_REVIEW_2_BY_CUSTOMER, TRANSITION_REVIEW_2_BY_PROVIDER, TRANSITION_SEND_MESSAGE_CUSTOMER, TRANSITION_SEND_MESSAGE_CUSTOMER_COMPLETED, TRANSITION_SEND_MESSAGE_CUSTOMER_DELIVERED, TRANSITION_SEND_MESSAGE_CUSTOMER_PURCHASED, TRANSITION_SEND_MESSAGE_CUSTOMER_RECEIVED, TRANSITION_SEND_MESSAGE_PROVIDER, TRANSITION_SEND_MESSAGE_PROVIDER_COMPLETED, TRANSITION_SEND_MESSAGE_PROVIDER_DELIVERED, TRANSITION_SEND_MESSAGE_PROVIDER_PURCHASED, TRANSITION_SEND_MESSAGE_PROVIDER_RECEIVED, TRANSITION_UPDATE_OFFER } from '../../util/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { onUpdateLastSeen } from '../../util/api';
import { fetchCurrentUser } from '../../ducks/user.duck';

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );


// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/InboxPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/InboxPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/InboxPage/FETCH_ORDERS_OR_SALES_ERROR';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactionRefs: [],
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const INBOX_PAGE_SIZE = 100;

export const updateLastSeen = (txId, seenParams) => async (dispatch, getState, sdk) => {

  const providerSeen = seenParams && seenParams.providerSeen;
  const customerSeen = seenParams && seenParams.customerSeen;
  const isCustomer = seenParams && seenParams.isCustomer;

  const params = isCustomer ? { txId: txId.uuid, providerSeen, customerSeen: true } : { txId: txId.uuid, providerSeen: true, customerSeen };
  const updatedLastSeen = params && params.txId && await onUpdateLastSeen(params);
  if (updatedLastSeen) {
    dispatch(fetchCurrentUser());
  }
  console.log(updatedLastSeen)
}

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { tab } = params;

  const onlyFilterValues = {
    orders: 'order',
    sales: 'sale',
  };

  const onlyFilter = onlyFilterValues[tab];
  // if (!onlyFilter) {
  //   return Promise.reject(new Error(`Invalid tab for InboxPage: ${tab}`));
  // }

  dispatch(fetchOrdersOrSalesRequest());

  const { page = 1 } = parse(search);

  const isCompleted = tab && tab === 'completed';
  const isOffer = tab && tab === 'offer';

  const apiQueryParams = {
    only: onlyFilter,
    lastTransitions: [
      TRANSITION_CONFIRM_PAYMENT,
      TRANSITION_CONFIRM_PAYMENT_WITH_PAYPAL,
      TRANSITION_AUTO_CANCEL,
      TRANSITION_AUTO_CANCEL_WITH_PAYPAL,
      TRANSITION_CANCEL,
      TRANSITION_CANCEL_WITH_PAYPAL,
      TRANSITION_MARK_RECEIVED_FROM_PURCHASED,
      TRANSITION_MARK_RECEIVED_FROM_PURCHASED_WITH_PAYPAL,
      TRANSITION_MARK_DELIVERED,
      TRANSITION_MARK_DELIVERED_WITH_PAYPAL,
      TRANSITION_DISPUTE,
      TRANSITION_DISPUTE_WITH_PAYPAL,
      TRANSITION_MARK_RECEIVED,
      TRANSITION_MARK_RECEIVED_WITH_PAYPAL,
      TRANSITION_AUTO_MARK_RECEIVED,
      TRANSITION_AUTO_MARK_RECEIVED_WITH_PAYPAL,
      TRANSITION_MARK_RECEIVED_FROM_DISPUTED,
      TRANSITION_MARK_RECEIVED_FROM_DISPUTED_WITH_PAYPAL,
      TRANSITION_AUTO_CANCEL_FROM_DISPUTED,
      TRANSITION_AUTO_CANCEL_FROM_DISPUTED_WITH_PAYPAL,
      TRANSITION_CANCEL_FROM_DISPUTED,
      TRANSITION_CANCEL_FROM_DISPUTED_WITH_PAYPAL,
      TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY_WITH_PAYPAL,
      TRANSITION_ENQUIRE,
      TRANSITION_UPDATE_OFFER,
      TRANSITION_SEND_MESSAGE_CUSTOMER,
      TRANSITION_SEND_MESSAGE_PROVIDER,
      TRANSITION_SEND_MESSAGE_PROVIDER_PURCHASED,
      TRANSITION_SEND_MESSAGE_CUSTOMER_PURCHASED,
      TRANSITION_SEND_MESSAGE_PROVIDER_RECEIVED,
      TRANSITION_SEND_MESSAGE_CUSTOMER_RECEIVED,
      TRANSITION_SEND_MESSAGE_PROVIDER_DELIVERED,
      TRANSITION_SEND_MESSAGE_CUSTOMER_DELIVERED,
      TRANSITION_SEND_MESSAGE_PROVIDER_COMPLETED,
      TRANSITION_SEND_MESSAGE_CUSTOMER_COMPLETED,
      TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
      TRANSITION_REVIEW_1_BY_PROVIDER, TRANSITION_REVIEW_1_BY_CUSTOMER,
      TRANSITION_AUTO_COMPLETE
    ],
    include: [
      'listing',
      'provider',
      'provider.profileImage',
      'customer',
      'customer.profileImage',
      'booking',
    ],
    'fields.transaction': [
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
      'lineItems',
      'metadata',
      'protectedData'
    ],
    'fields.listing': ['title'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    page,
    per_page: INBOX_PAGE_SIZE,
  };

  const completeQueryParams = {
    lastTransitions: [TRANSITION_REVIEW_2_BY_PROVIDER, TRANSITION_REVIEW_2_BY_CUSTOMER, TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD, TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD, TRANSITION_EXPIRE_REVIEW_PERIOD],
    include: [
      'listing',
      'provider',
      'provider.profileImage',
      'customer',
      'customer.profileImage',
      'booking',
    ],
    'fields.transaction': [
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
      'lineItems',
      'metadata'
    ],
    'fields.listing': ['title'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    page,
    per_page: INBOX_PAGE_SIZE,
  };

  const offerQueryParams = {
    lastTransitions: [TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY_WITH_PAYPAL, TRANSITION_ENQUIRE, TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY, TRANSITION_UPDATE_OFFER],
    include: [
      'listing',
      'provider',
      'provider.profileImage',
      'customer',
      'customer.profileImage',
      'booking',
    ],
    'fields.transaction': [
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
      'lineItems',
      'metadata'
    ],
    'fields.listing': ['title'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    page,
    per_page: INBOX_PAGE_SIZE,
  };

  const tabQueryParams = isCompleted ? completeQueryParams : isOffer ? offerQueryParams : apiQueryParams;

  return sdk.transactions
    .query(tabQueryParams)
    .then(response => {
      console.log(response);
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchOrdersOrSalesSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchOrdersOrSalesError(storableError(e)));
      throw e;
    });
};
